import { IconProps } from './icon.types';

const HierarchicalStructure: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_982_1225)">
                <path
                    d="M49.375 60.078C50.238 60.078 50.9375 59.3783 50.9375 58.5155V50.703C50.9375 49.8402 50.238 49.1405 49.375 49.1405H41.5625V39.7653H49.375C50.238 39.7653 50.9375 39.0656 50.9375 38.2028V35.8591H61.25C62.113 35.8591 62.8125 35.1594 62.8125 34.2966C62.8125 33.4338 62.113 32.7341 61.25 32.7341H50.9375V30.3903C50.9375 29.5275 50.238 28.8278 49.375 28.8278H41.5625V21.3505L50.4798 12.4331C50.7728 12.14 50.9375 11.7427 50.9375 11.3283C50.9375 10.9139 50.7728 10.5166 50.4798 10.2235L41.1048 0.848613C40.4945 0.238457 39.5053 0.23877 38.895 0.848613L29.52 10.2235C29.227 10.5166 29.0623 10.9139 29.0623 11.3283C29.0623 11.7427 29.227 12.14 29.52 12.4331L38.4375 21.3505V28.8278H30.625C29.762 28.8278 29.0625 29.5275 29.0625 30.3903V32.7341H18.75C17.887 32.7341 17.1875 33.4338 17.1875 34.2966C17.1875 35.1594 17.887 35.8591 18.75 35.8591H29.0625V38.2028C29.0625 39.0656 29.762 39.7653 30.625 39.7653H38.4375V49.1405H30.625C29.762 49.1405 29.0625 49.8402 29.0625 50.703V58.5155C29.0625 59.3783 29.762 60.078 30.625 60.078H38.4375V71.0156C38.4375 71.8785 39.137 72.5781 40 72.5781C40.863 72.5781 41.5625 71.8785 41.5625 71.0156V60.078H49.375ZM40 4.16314L47.1652 11.3281L40 18.4935L32.8347 11.3283L40 4.16314ZM32.1875 31.9528H47.8125V36.6403H32.1875V31.9528ZM32.1875 52.2655H47.8125V56.953H32.1875V52.2655Z"
                    fill="#212529"
                />
                <path
                    d="M78.4375 49.1403H70.625V42.1089C70.625 41.2461 69.9255 40.5464 69.0625 40.5464C68.1995 40.5464 67.5 41.2461 67.5 42.1089V49.1403H59.6875C58.8245 49.1403 58.125 49.84 58.125 50.7028V58.5153C58.125 59.3781 58.8245 60.0778 59.6875 60.0778H67.5V73.3592H49.375C48.512 73.3592 47.8125 74.0589 47.8125 74.9217C47.8125 75.7845 48.512 76.4842 49.375 76.4842H69.0625C69.9255 76.4842 70.625 75.7845 70.625 74.9217V60.0778H78.4375C79.3005 60.0778 80 59.3781 80 58.5153V50.7028C80 49.84 79.3005 49.1403 78.4375 49.1403ZM76.875 56.9528H61.25V52.2653H76.875V56.9528Z"
                    fill="#212529"
                />
                <path
                    d="M30.625 73.3592H12.5V60.0778H20.3125C21.1755 60.0778 21.875 59.3781 21.875 58.5153V50.7028C21.875 49.84 21.1755 49.1403 20.3125 49.1403H12.5V42.1089C12.5 41.2461 11.8005 40.5464 10.9375 40.5464C10.0745 40.5464 9.375 41.2461 9.375 42.1089V49.1403H1.5625C0.699531 49.1403 0 49.84 0 50.7028V58.5153C0 59.3781 0.699531 60.0778 1.5625 60.0778H9.375V74.9217C9.375 75.7845 10.0745 76.4842 10.9375 76.4842H30.625C31.488 76.4842 32.1875 75.7845 32.1875 74.9217C32.1875 74.0589 31.488 73.3592 30.625 73.3592ZM3.125 52.2653H18.75V56.9528H3.125V52.2653Z"
                    fill="#212529"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M29.8438 70.2344H50.1562V79.6094H29.8438V70.2344Z"
                    fill="url(#paint0_linear_982_1225)"
                    fill-opacity="0.7"
                />
                <path
                    d="M18.9205 37.4705C20.6766 33.0613 18.5258 28.0633 14.1166 26.3072C9.70737 24.5511 4.7094 26.7019 2.95331 31.1111C1.19722 35.5203 3.348 40.5183 7.75721 42.2744C12.1664 44.0305 17.1644 41.8797 18.9205 37.4705Z"
                    fill="url(#paint1_linear_982_1225)"
                    fill-opacity="0.7"
                />
                <path
                    d="M75.1274 40.3761C78.4829 37.0205 78.4829 31.5801 75.1274 28.2245C71.7718 24.869 66.3314 24.869 62.9758 28.2245C59.6202 31.5801 59.6202 37.0205 62.9758 40.3761C66.3314 43.7316 71.7718 43.7316 75.1274 40.3761Z"
                    fill="url(#paint2_linear_982_1225)"
                    fill-opacity="0.7"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_982_1225"
                    x1="43.3747"
                    y1="70.9409"
                    x2="48.5711"
                    y2="73.427"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#E2C4FF" />
                    <stop offset="1" stop-color="#E9D2FF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_982_1225"
                    x1="5.21327"
                    y1="28.9376"
                    x2="8.17644"
                    y2="24.5722"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#E2C4FF" />
                    <stop offset="1" stop-color="#E9D2FF" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_982_1225"
                    x1="65.9105"
                    y1="27.1215"
                    x2="70.3574"
                    y2="24.2833"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#E2C4FF" />
                    <stop offset="1" stop-color="#E9D2FF" />
                </linearGradient>
                <clipPath id="clip0_982_1225">
                    <rect width="80" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default HierarchicalStructure;
