import styled from 'styled-components';
import convertSize from '../../helpers/convertSize';
import { Typography } from '../../ui-kit';

export const Error = styled(Typography)`
    position: absolute;
    bottom: -${convertSize(25)};
    left: ${convertSize(5)};
    color: darkred;
    letter-spacing: 0;
    font-size: ${convertSize(12)};

    @media (max-width: 1440px) {
        bottom: -25px;
        left: 5px;
        font-size: 12px;
    }
`;
