import styled from 'styled-components';
import convertSize from '../../helpers/convertSize';

export const WrapperContainer = styled.div`
    display: flex;
    justify-content: inherit;
    align-items: inherit;
    width: ${convertSize(1200)};
    margin: 0 auto;

    @media (max-width: 1440px) {
        width: 1200px;
    }
`;
