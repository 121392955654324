import { Route, Routes, useNavigate } from 'react-router-dom';

import { ICON_TYPES } from '../../shared';
import PATH from '../../shared/helpers/appRoutes';
import * as Styled from './thank-you.styles';

const ThankYou = () => {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(PATH.home);
    };

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Styled.Container>
                        <Styled.Content>
                            <Styled.IconFirstCircle>
                                <Styled.IconSecondCircle>
                                    <Styled.StyledIcon type={ICON_TYPES.like} />
                                </Styled.IconSecondCircle>
                            </Styled.IconFirstCircle>

                            <Styled.Title type="h3">Thank you!</Styled.Title>
                            <Styled.Description type="h6">
                                Our sales representative will get back to you soon.
                            </Styled.Description>
                            <Styled.Button onClick={onClick}>Go back home</Styled.Button>
                        </Styled.Content>
                    </Styled.Container>
                }
            />
        </Routes>
    );
};

export default ThankYou;
