import React, { useState, createContext } from 'react';
import { createPortal } from 'react-dom';

import { Toast } from '../../ui-kit/toast';
import { ToastType } from '../../ui-kit/toast/toast.component';

export interface ToastContextType {
    open: (data: { content: string; type: ToastType }) => void;
}

export const ToastContext = createContext<ToastContextType>({
    open: () => {},
});

// Create a random ID
function generateUEID() {
    let first = Math.random() * 46656 || 0;
    let second = Math.random() * 46656 || 0;
    //@ts-ignore
    first = ('000' + first.toString(36)).slice(-3);
    //@ts-ignore
    second = ('000' + second.toString(36)).slice(-3);

    return first + second;
}

interface ToastProviderProps {
    children: React.ReactNode;
}

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
    const [toasts, setToasts] = useState<{ content: string; type: ToastType; id: number }[]>([]);
    const open = ({ content, type }: { content: string; type: ToastType }) =>
        setToasts((currentToasts) => [...currentToasts, { id: generateUEID(), content, type }]);
    const close = (id: number) => setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id));
    const contextValue = { open };

    return (
        <ToastContext.Provider value={contextValue}>
            {children}
            {createPortal(
                <div style={{ position: 'fixed', top: 20, right: 20, zIndex: 1000 }}>
                    {toasts.map(({ id, content, type }) => {
                        debugger;
                        const onClose = () => close(id);
                        return (
                            <Toast key={id} type={type} close={onClose}>
                                {content}
                            </Toast>
                        );
                    })}
                </div>,
                document.body
            )}
        </ToastContext.Provider>
    );
};

export default ToastProvider;
