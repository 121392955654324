import * as Styled from './features.styles';
import { LeftContent } from './components/left-content';
import { RightContent } from './components/right-content';

const Features = () => {
    return (
        <Styled.FeaturesContainer id='feature-container'>
            <Styled.FeatureContent>
                <LeftContent />
                <RightContent />
            </Styled.FeatureContent>
        </Styled.FeaturesContainer>
    );
};

export default Features;
