import { IconProps } from './icon.types';

const LiveStreaming: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.722 20.668H63.2778V45.6813H16.722V20.668Z" fill="#B3F5FE"/>
        <path d="M78.4375 56.6898H76.3622V13.88C76.3622 10.7923 73.8502 8.28027 70.7625 8.28027H9.2375C6.14984 8.28027 3.63781 10.7923 3.63781 13.88V56.6898H1.5625C0.699688 56.6898 0 57.3893 0 58.2523V62.2221C0 67.4589 4.26047 71.7192 9.49703 71.7192H70.503C75.7397 71.7192 80 67.4589 80 62.2221V58.2523C80 57.3893 79.3003 56.6898 78.4375 56.6898ZM6.76281 13.88C6.76281 12.5154 7.87281 11.4053 9.2375 11.4053H70.7625C72.127 11.4053 73.2372 12.5154 73.2372 13.88V56.6898H57.5931C56.7303 56.6898 56.0306 57.3893 56.0306 58.2523V61.0795H23.9694V58.2523C23.9694 57.3893 23.2697 56.6898 22.4069 56.6898H6.76281V13.88ZM76.875 62.2223C76.875 65.7359 74.0166 68.5943 70.503 68.5943H9.49703C5.98344 68.5942 3.125 65.7357 3.125 62.2223V59.815H20.8444V62.6422C20.8444 63.5051 21.5441 64.2047 22.4069 64.2047H57.593C58.4558 64.2047 59.1555 63.5051 59.1555 62.6422V59.815H76.875V62.2223Z" fill="#171A1D"/>
        <path d="M25.2623 40.0095C25.3758 40.0291 25.4509 40.042 26.6283 40.042C27.1994 40.042 28.0303 40.0389 29.2502 40.0322C30.1131 40.0273 30.8088 39.3239 30.8039 38.4609C30.7989 37.5981 30.1005 36.9058 29.2327 36.9072C28.5019 36.9112 27.7317 36.9144 27.0894 36.9155V27.8711C27.0894 27.0081 26.3897 26.3086 25.5269 26.3086C24.6641 26.3086 23.9644 27.0081 23.9644 27.8711V38.4697C23.9645 39.2303 24.5125 39.8806 25.2623 40.0095Z" fill="#171A1D"/>
        <path d="M34.2047 40.0413C35.0675 40.0413 35.7672 39.3417 35.7672 38.4788V27.8711C35.7672 27.0081 35.0675 26.3086 34.2047 26.3086C33.3419 26.3086 32.6422 27.0081 32.6422 27.8711V38.4788C32.6422 39.3417 33.3419 40.0413 34.2047 40.0413Z" fill="#171A1D"/>
        <path d="M50.0442 40.0411H54.473C55.3358 40.0411 56.0355 39.3415 56.0355 38.4786C56.0355 37.6156 55.3358 36.9161 54.473 36.9161H51.6067V34.7856H54.1464C55.0092 34.7856 55.7089 34.0861 55.7089 33.2231C55.7089 32.3601 55.0092 31.6606 54.1464 31.6606H51.6067V29.5303H54.473C55.3358 29.5303 56.0355 28.8307 56.0355 27.9678C56.0355 27.1048 55.3358 26.4053 54.473 26.4053H50.0442C49.1814 26.4053 48.4817 27.1048 48.4817 27.9678V38.4786C48.4817 39.3415 49.1814 40.0411 50.0442 40.0411Z" fill="#171A1D"/>
        <path d="M40.5077 38.8419C40.7628 39.5603 41.4441 40.0407 42.2055 40.0407H42.2177C42.9841 40.0355 43.6648 39.5446 43.915 38.8085L47.3784 28.3827C47.6505 27.5636 47.207 26.6793 46.3881 26.4072C45.5702 26.1355 44.685 26.5785 44.4127 27.3974L42.1778 34.1247L39.8292 27.3582C39.5462 26.5432 38.6564 26.1114 37.8406 26.3944C37.0253 26.6774 36.5939 27.5677 36.8769 28.383L40.5077 38.8419Z" fill="#171A1D"/>
        </svg>
        
    );
};

export default LiveStreaming;
