import ReactGA from 'react-ga';
import { useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Introduction } from './introduction';
import { Benefit } from './benefit';
import { ChatBotHelp } from './chat-bot-help';
import { Features } from './features';
import { HowWork } from './how-work';
import { Bespeak } from './bespeak';
import { Companies } from './companies';
import { CustomersSay } from './customers-say';

const Home = () => {
    const intersectTarget = useRef(null);

    useEffect(() => {
        const opts = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        };
        const callback = (entries: IntersectionObserverEntry[]) => {
            const [target] = entries;
            if (target.isIntersecting) {
                ReactGA.event({
                    category: 'Scroll',
                    action: 'Scrolled to Benefit section',
                    value: entries[0].intersectionRatio,
                });
            }
        };
        if (intersectTarget.current) {
            const observerScroll = new IntersectionObserver(callback, opts);
            observerScroll.observe(intersectTarget.current);
        }
    }, []);

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <>
                        <Introduction />
                        <Companies />
                        <Benefit ref={intersectTarget} />
                        <ChatBotHelp />
                        <Features />
                        <HowWork />
                        <CustomersSay />
                        <Bespeak />
                    </>
                }
            />
        </Routes>
    );
};

export default Home;
