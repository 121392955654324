import { IconProps } from './icon.types';

const Calendar: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_619_1307)">
                <path d="M4.70587 17.4117H43.2941V40H4.70587V17.4117Z" fill="#B2F0FB" />
                <path
                    d="M47.0588 7.99995H41.4118V4.23524C41.4118 3.71543 40.9904 3.29407 40.4706 3.29407H35.7647C35.2449 3.29407 34.8235 3.71543 34.8235 4.23524V7.99995H13.1765V4.23524C13.1765 3.71543 12.7551 3.29407 12.2353 3.29407H7.52941C7.0096 3.29407 6.58824 3.71543 6.58824 4.23524V7.99995H0.941176C0.421365 7.99995 0 8.42131 0 8.94113V17.4117V43.7647C0 44.2845 0.421365 44.7058 0.941176 44.7058H47.0588C47.5786 44.7058 48 44.2845 48 43.7647V17.4117V8.94113C48 8.42131 47.5786 7.99995 47.0588 7.99995ZM36.7059 5.17642H39.5294V12.7058H36.7059V5.17642ZM8.47059 5.17642H11.2941V12.7058H8.47059V5.17642ZM1.88235 9.8823H6.58824V13.647C6.58824 14.1668 7.0096 14.5882 7.52941 14.5882H12.2353C12.7551 14.5882 13.1765 14.1668 13.1765 13.647V9.8823H34.8235V13.647C34.8235 14.1668 35.2449 14.5882 35.7647 14.5882H40.4706C40.9904 14.5882 41.4118 14.1668 41.4118 13.647V9.8823H46.1176V16.4705H1.88235V9.8823ZM46.1176 42.8235H1.88235V18.3529H46.1176V42.8235Z"
                    fill="#212529"
                />
                <path
                    d="M17.2058 27.9116H22.0587C22.5785 27.9116 22.9999 27.4903 22.9999 26.9705V22.1176C22.9999 21.5978 22.5785 21.1764 22.0587 21.1764H17.2058C16.686 21.1764 16.2646 21.5978 16.2646 22.1176V26.9705C16.2646 27.4903 16.6861 27.9116 17.2058 27.9116ZM18.147 23.0587H21.1175V26.0293H18.147V23.0587Z"
                    fill="#212529"
                />
                <path
                    d="M25.9412 27.9116H30.7941C31.3139 27.9116 31.7352 27.4903 31.7352 26.9705V22.1176C31.7352 21.5978 31.3139 21.1764 30.7941 21.1764H25.9412C25.4214 21.1764 25 21.5978 25 22.1176V26.9705C25 27.4903 25.4214 27.9116 25.9412 27.9116ZM26.8824 23.0587H29.8529V26.0293H26.8824V23.0587Z"
                    fill="#212529"
                />
                <path
                    d="M34.6765 27.9116H39.5294C40.0492 27.9116 40.4706 27.4903 40.4706 26.9705V22.1176C40.4706 21.5978 40.0492 21.1764 39.5294 21.1764H34.6765C34.1567 21.1764 33.7354 21.5978 33.7354 22.1176V26.9705C33.7354 27.4903 34.1567 27.9116 34.6765 27.9116ZM35.6177 23.0587H38.5882V26.0293H35.6177V23.0587Z"
                    fill="#212529"
                />
                <path
                    d="M8.4706 36.2352H13.3235C13.8433 36.2352 14.2647 35.8139 14.2647 35.2941V30.4412C14.2647 29.9214 13.8433 29.5 13.3235 29.5H8.4706C7.95078 29.5 7.52942 29.9214 7.52942 30.4412V35.2941C7.52942 35.8139 7.95078 36.2352 8.4706 36.2352ZM9.41177 31.3824H12.3823V34.3529H9.41177V31.3824Z"
                    fill="#212529"
                />
                <path
                    d="M17.2058 36.2352H22.0587C22.5785 36.2352 22.9999 35.8139 22.9999 35.2941V30.4412C22.9999 29.9214 22.5785 29.5 22.0587 29.5H17.2058C16.686 29.5 16.2646 29.9214 16.2646 30.4412V35.2941C16.2646 35.8139 16.6861 36.2352 17.2058 36.2352ZM18.147 31.3824H21.1175V34.3529H18.147V31.3824Z"
                    fill="#212529"
                />
                <path
                    d="M25.9412 36.2352H30.7941C31.3139 36.2352 31.7352 35.8139 31.7352 35.2941V30.4412C31.7352 29.9214 31.3139 29.5 30.7941 29.5H25.9412C25.4214 29.5 25 29.9214 25 30.4412V35.2941C25 35.8139 25.4214 36.2352 25.9412 36.2352ZM26.8824 31.3824H29.8529V34.3529H26.8824V31.3824Z"
                    fill="#212529"
                />
            </g>
            <defs>
                <clipPath id="clip0_619_1307">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Calendar;
