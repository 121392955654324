import React from 'react';
import { IconProps, E_ICON_TYPE, T_MAP_TYPE } from './icon.types';
import Apartment from './apartment.component';
import Arrow from './arrow.component';
import ArrowDown from './arrow-down.component';
import Brain from './brain.component';
import Browser from './browser.component';
import Calendar from './calendar.component';
import Chat from './chat.component';
import ChatBot from './chat-bot.component';
import CodexLogo from './codex-logo.component';
import CustomerSupport from './customer-support.component';
import Discussion from './discussion.component';
import Email from './email.component';
import EmailLogo from './email-logo.component';
import HeartRate from './heart-rate.component';
import HierarchicalStructure from './hierarchical-structure.component';
import LinkedInLogo from './linkedIn-logo.component';
import LiveStreaming from './live-streaming.component';
import Logo from './logo.component';
import OnlineBooking from './online-booking.component';
import Responsive from './responsive.component';
import Scissors from './scissors.component';
import OutlineDone from './outline-done.component';
import ArrowToggle from './arrow-toggle.component';
import YahooMail from './yahoo-mail.component';
import Document from './document.component';
import MoneyBag from './money-bag.component';
import PieChart from './pie-chart.component';
import Profits from './profits.component';
import Service from './service.component';
import Close from './close.component';
import Like from './like.component';
import Global from './global.component';

const MAP: T_MAP_TYPE = {
    [E_ICON_TYPE.apartment]: (props) => <Apartment {...props} />,
    [E_ICON_TYPE.arrowDown]: (props) => <ArrowDown {...props} />,
    [E_ICON_TYPE.arrow]: (props) => <Arrow {...props} />,
    [E_ICON_TYPE.brain]: (props) => <Brain {...props} />,
    [E_ICON_TYPE.browser]: (props) => <Browser {...props} />,
    [E_ICON_TYPE.calendar]: (props) => <Calendar {...props} />,
    [E_ICON_TYPE.chat]: (props) => <Chat {...props} />,
    [E_ICON_TYPE.chatBot]: (props) => <ChatBot {...props} />,
    [E_ICON_TYPE.codexLogo]: (props) => <CodexLogo {...props} />,
    [E_ICON_TYPE.customerSupport]: (props) => <CustomerSupport {...props} />,
    [E_ICON_TYPE.discussion]: (props) => <Discussion {...props} />,
    [E_ICON_TYPE.email]: (props) => <Email {...props} />,
    [E_ICON_TYPE.emailLogo]: (props) => <EmailLogo {...props} />,
    [E_ICON_TYPE.heartRate]: (props) => <HeartRate {...props} />,
    [E_ICON_TYPE.hierarchicalStructure]: (props) => <HierarchicalStructure {...props} />,
    [E_ICON_TYPE.linkedInLogo]: (props) => <LinkedInLogo {...props} />,
    [E_ICON_TYPE.liveStreaming]: (props) => <LiveStreaming {...props} />,
    [E_ICON_TYPE.logo]: (props) => <Logo {...props} />,
    [E_ICON_TYPE.onlineBooking]: (props) => <OnlineBooking {...props} />,
    [E_ICON_TYPE.responsive]: (props) => <Responsive {...props} />,
    [E_ICON_TYPE.scissors]: (props) => <Scissors {...props} />,
    [E_ICON_TYPE.outlineDone]: (props) => <OutlineDone {...props} />,
    [E_ICON_TYPE.arrowToggle]: (props) => <ArrowToggle {...props} />,
    [E_ICON_TYPE.yahooMail]: (props) => <YahooMail {...props} />,
    [E_ICON_TYPE.document]: (props) => <Document {...props} />,
    [E_ICON_TYPE.moneyBag]: (props) => <MoneyBag {...props} />,
    [E_ICON_TYPE.pieChart]: (props) => <PieChart {...props} />,
    [E_ICON_TYPE.profits]: (props) => <Profits {...props} />,
    [E_ICON_TYPE.service]: (props) => <Service {...props} />,
    [E_ICON_TYPE.close]: (props) => <Close {...props} />,
    [E_ICON_TYPE.like]: (props) => <Like {...props} />,
    [E_ICON_TYPE.global]: (props) => <Global {...props} />,
};

const Icon: React.FC<IconProps> = (props) => {
    const { type } = props;
    return MAP[type](props);
};

export const ICON_TYPES = E_ICON_TYPE;
export default Icon;
