import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import * as Styled from './app.styles';
import { Footer, Header } from './shared';
import { Home } from './pages/home';
import { ThankYou } from './pages/thank-you';
import PATH from './shared/helpers/appRoutes';

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID || 'YOUR_TRACKING_ID';
ReactGA.initialize(TRACKING_ID);

const App = () => {
    const location = useLocation();

    useEffect(() => {
        const page = location.pathname;
        ReactGA.set({ page });
        ReactGA.pageview(page);
    }, [location]);

    return (
        <Styled.AppContainer>
            <Header />
            <Routes>
                <Route path={`${PATH.home}/*`} element={<Home />} />
                <Route path={`${PATH.thankYou}/*`} element={<ThankYou />} />
                <Route path="*" element={<Navigate to={PATH.home} />} />
            </Routes>
            <Footer />
        </Styled.AppContainer>
    );
};

export default App;
