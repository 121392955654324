import { IconProps } from './icon.types';

const ArrowDown: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M40 24H8M26 38L40 24L26 10"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default ArrowDown;
