import styled from 'styled-components';
import { Button as StyledButton, Icon, Typography } from '../../shared';
import convertSize from '../../shared/helpers/convertSize';

export const Container = styled.section`
    height: calc(100vh - ${convertSize(160)});
    background-color: #f5f5f5;

    @media (max-width: 1440px) {
        height: calc(100vh - 160px);
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: ${convertSize(1200)};
    width: auto;
    margin: 0 auto;
    height: 100%;

    @media (max-width: 1440px) {
        max-width: 1200px;
    }
`;

export const IconFirstCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(164, 252, 220, 0.2);
    width: ${convertSize(280)};
    height: ${convertSize(280)};

    @media (max-width: 1440px) {
        width: 280px;
        height: 280px;
    }
`;

export const IconSecondCircle = styled(IconFirstCircle)`
    width: ${convertSize(200)};
    height: ${convertSize(200)};
    background-color: rgba(164, 252, 220, 0.4);
    margin-bottom: 0;

    @media (max-width: 1440px) {
        width: 200px;
        height: 200px;
    }
`;

export const Title = styled(Typography)`
    margin: ${convertSize(32)} 0 ${convertSize(12)};

    @media (max-width: 1440px) {
        width: 200px;
        height: 200px;
    }
`;

export const Description = styled(Title)`
    margin: 0 0 ${convertSize(32)};
    font-weight: 400;
    letter-spacing: 0;

    @media (max-width: 1440px) {
        margin: 0 0 32px;
    }

    @media (max-width: 479px) {
        text-align: center;
        width: 340px;
    }
`;

export const Button = styled(StyledButton)`
    width: ${convertSize(416)};
    height: ${convertSize(64)};
    font-family: 'SF Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${convertSize(18)};
    line-height: ${convertSize(24)};
    color: #ffffff;

    @media (max-width: 1440px) {
        width: 416px;
        height: 64px;
        font-size: 18px;
        line-height: 24px;
    }

    @media (max-width: 767px) {
        width: 200px;
    }
`;

export const StyledIcon = styled(Icon)`
    width: ${convertSize(80)};
    height: ${convertSize(80)};

    @media (max-width: 1440px) {
        width: 80px;
        height: 80px;
    }
`;
