import styled from 'styled-components';
import { Typography } from '../../../shared';
import convertSize from '../../../shared/helpers/convertSize';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: ${convertSize(150)};
    background-color: #f5f5f5;

    @media (max-width: 1440px) {
        padding-bottom: 150px;
    }

    @media (max-width: 1240px) {
        padding: 0px 20px 150px 20px;
        box-sizing: border-box;
    }
`;
export const TitleBlock = styled.div`
    width: ${convertSize(760)};
    text-align: center;
    margin-bottom: ${convertSize(28)};

    @media (max-width: 1440px) {
        width: 760px;
        margin-bottom: 28px;
    }

    @media (max-width: 767px) {
        width: auto;
    }
`;

export const Cards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: ${convertSize(72)};
    max-width: ${convertSize(1200)};
    width: auto;
    height: 100%;
    margin: 0 auto;

    @media (max-width: 1440px) {
        grid-gap: 72px;
        max-width: 1200px;
    }

    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: ${convertSize(8)};
    width: ${convertSize(454)};
    height: ${convertSize(254)};
    padding: ${convertSize(30)};
    box-sizing: border-box;
    box-shadow: ${convertSize(50)} ${convertSize(50)} ${convertSize(100)} rgba(0, 0, 0, 0.145947);

    @media (max-width: 1440px) {
        border-radius: 8px;
        width: 454px;
        height: 254px;
        padding: 30px;
        box-shadow: 50px 50px 100px rgba(0, 0, 0, 0.145947);
    }

    @media (max-width: 767px) {
        width: 410px;
        height: 280px;
    }

    @media (max-width:  479px) {
        width: auto;
        height: auto;
    }
`;

export const Reviewer = styled(Typography)`
    line-height: ${convertSize(21)};
    font-size: ${convertSize(16)};

    @media (max-width: 1440px) {
        line-height: 21px;
        font-size: 16px;
    }
`;

export const Review = styled(Reviewer)`
    font-size: ${convertSize(16)};
    line-height: ${convertSize(26)};
    letter-spacing: 0;
    font-style: italic;

    @media (max-width: 1440px) {
        font-size: 16px;
        line-height: 26px;
    }
`;

export const Chief = styled(Typography)`
    font-size: ${convertSize(14)};
    line-height: ${convertSize(19)};
    letter-spacing: 0;

    @media (max-width: 1440px) {
        font-size: 14px;
        line-height: 19px;
    }
`;

export const ReviewerContainer = styled.div`
    display: flex;
    align-items: center;

    @media (max-width:  479px) {
       margin-top: 12px;
    }
`;
export const ReviewerBlock = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${convertSize(14)};

    @media (max-width: 1440px) {
        margin-left: 14px;
    }
`;

export const CompanyIcon = styled.img<{ width: number; height: number }>`
    width: ${({ width }) => convertSize(width)};
    height: ${({ height }) => convertSize(height)};

    @media (max-width: 1440px) {
        width: ${({ width }) => `${width}px`};
        height: ${({ height }) => `${height}px`};
    }
`;
