import React from 'react';
import * as Styled from './input.styles';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    ref?: React.Ref<HTMLInputElement>;
} & {
    value?: string;
};

const Input: React.ForwardRefExoticComponent<InputProps> = React.forwardRef<HTMLInputElement, InputProps>(
    (props, ref) => (
        <Styled.Container>
            <Styled.Element ref={ref} {...props} autoComplete="off" />
        </Styled.Container>
    )
);

export default Input;
