import styled from 'styled-components';
import convertSize from '../../helpers/convertSize';

export const RegularText = styled.p`
    margin: 0;
    font-family: 'SF Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${convertSize(18)};
    line-height: ${convertSize(28)};
    letter-spacing: 0.05em;
    color: #212529;

    @media (max-width: 1440px) {
        font-size: 18px;
        line-height: 28px;
    }
`;

export const H6Text = styled(RegularText)`
    font-weight: 700;
    line-height: ${convertSize(24)};

    @media (max-width: 1440px) {
        line-height: 24px;
    }
`;

export const H3Text = styled(RegularText)`
    font-weight: 500;
    font-size: ${convertSize(48)};
    line-height: ${convertSize(57)};

    @media (max-width: 1440px) {
        font-size: 48px;
        line-height: 57px;
    }

    @media (max-width: 767px) {
        font-size: 40px;
        line-height: 47px;
    }

    @media (max-width: 479px) {
        font-size: 32px;
        line-height: 38px;
    }
`;

export const H1Text = styled(RegularText)`
    font-weight: 500;
    font-size: ${convertSize(72)};
    line-height: ${convertSize(86)};

    @media (max-width: 1440px) {
        font-size: 72px;
        line-height: 86px;
    }

    @media (max-width: 767px) {
        font-size: 60px;
        line-height: 71px;
    }

    @media (max-width: 479px) {
        font-size: 48px;
        line-height: 57px;
    }
`;
