import { IconProps } from './icon.types';

const YahooMail: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.75 17.75V6.25C21.75 5.98478 21.6446 5.73043 21.4571 5.54289C21.2696 5.35536 21.0152 5.25 20.75 5.25H3.25C2.98478 5.25 2.73043 5.35536 2.54289 5.54289C2.35536 5.73043 2.25 5.98478 2.25 6.25V17.75C2.25 18.0152 2.35536 18.2696 2.54289 18.4571C2.73043 18.6446 2.98478 18.75 3.25 18.75H20.75C21.0152 18.75 21.2696 18.6446 21.4571 18.4571C21.6446 18.2696 21.75 18.0152 21.75 17.75Z"
                stroke="#171a1d"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <path d="M21.75 7.49048L12 12L2.25 7.49048"  stroke-width="1.5" stroke="#171a1d" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default YahooMail;
