import { useState } from 'react';

import { ICON_TYPES } from '../../../../../shared';
import * as Styled from './left-content.styles';

const leftSideContent = [
    {
        title: 'All basic features',
        list: [
            'clickable buttons',
            'scheduling actions',
            'sending emojis, images and image carousels',
            'sending videos and audios',
            'sending attachments',
            'bot admin page',
        ],
    },
    { title: 'Creation of webhooks from conversations', list: [] },
    { title: 'Ability to Send HTTP requests from conversations', list: [] },
    { title: 'Multilingual support', list: [] },
    {
        title: 'AI components',
        list: [
            'Detection of visitor intention (question, conversation with a human operator, support request, etc)',
            'Sentiment analysis (the bot will detect emotions of the visitor and adjust conversation accordingly)',
            'Entity extraction - extraction of valuable data from conversations',
        ],
    },
    {
        title: 'Integrations with appointment software, CRM, ATS, and other essential 3rd-party software',
        list: [],
    },
    {
        title: 'Analytics including gathering, processing and data visualization',
        list: [],
    },
    {
        title: 'Flexible infrastructure - a bot may run on a local server or cloud platforms (AWS, GCP, Azure)',
        list: [],
    },
    { title: 'Other features upon request', list: [] },
];
const LeftContent = () => {
    const [isToggle, setIsToggle] = useState<{ [key: string]: boolean }>({
        0: false,
        4: false,
    });

    return (
        <Styled.LeftSide>
            <Styled.Title type="h3">Possible features</Styled.Title>
            {leftSideContent.map(({ title, list }, index) => {
                const onClick = () => {
                    setIsToggle((prevState) => ({
                        ...prevState,
                        [index]: !prevState[index],
                    }));
                };
                return (
                    <Styled.List key={title}>
                        <Styled.ListTitleContainer>
                            <div>
                                <Styled.StyledIcon type={ICON_TYPES.outlineDone} />
                            </div>
                            <Styled.ListTitle type="h6">{title}</Styled.ListTitle>
                            {list.length > 0 && (
                                <Styled.ToggleIcon
                                    isToggle={isToggle[index]}
                                    type={ICON_TYPES.arrowToggle}
                                    onClick={onClick}
                                />
                            )}
                        </Styled.ListTitleContainer>
                        {list.length > 0 &&
                            isToggle[index] &&
                            list.map((item) => (
                                <Styled.Item key={item}>
                                    <Styled.ItemText>{item}</Styled.ItemText>
                                </Styled.Item>
                            ))}
                    </Styled.List>
                );
            })}
        </Styled.LeftSide>
    );
};

export default LeftContent;
