import { IconProps } from './icon.types';

const LinkedInLogo: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 0.640015C7.51682 0.640015 0.640015 7.51682 0.640015 16C0.640015 24.4832 7.51682 31.36 16 31.36C24.4832 31.36 31.36 24.4832 31.36 16C31.36 7.51682 24.4832 0.640015 16 0.640015ZM12.24 22.3664H9.12961V12.3568H12.24V22.3664ZM10.6656 11.128C9.68321 11.128 9.04801 10.432 9.04801 9.57121C9.04801 8.69281 9.70241 8.01761 10.7056 8.01761C11.7088 8.01761 12.3232 8.69281 12.3424 9.57121C12.3424 10.432 11.7088 11.128 10.6656 11.128ZM23.6 22.3664H20.4896V16.8192C20.4896 15.528 20.0384 14.6512 18.9136 14.6512C18.0544 14.6512 17.544 15.2448 17.3184 15.816C17.2352 16.0192 17.2144 16.3072 17.2144 16.5936V22.3648H14.1024V15.5488C14.1024 14.2992 14.0624 13.2544 14.0208 12.3552H16.7232L16.8656 13.7456H16.928C17.3376 13.0928 18.3408 12.1296 20.0192 12.1296C22.0656 12.1296 23.6 13.5008 23.6 16.448V22.3664Z"
                fill="white"
            />
        </svg>
    );
};

export default LinkedInLogo;
