export const fieldRequired = (value: string) => (value ? undefined : 'Field is required');

export const composeValidators =
    (...validators: any[]) =>
    (value: string) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

export const maxLength = (max: number) => (value: string) => {
    if (!value) return undefined;
    return value.length < max ? undefined : `Describe question should be less than ${max} characters`;
};
