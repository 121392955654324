import { SyntheticEvent } from 'react';

import { ICON_TYPES, Wrapper } from '../../ui-kit';
import * as Styled from './footer.styles';

const Footer = () => {
    const onEmailClick = (event: SyntheticEvent) => {
        event.preventDefault();
        window.location.href = `mailto:${process.env.REACT_APP_EMAIL}`;
    };

    return (
        <Styled.Footer>
            <Wrapper>
                <Styled.IconContainer>
                    <Styled.Logo type={ICON_TYPES.codexLogo} />
                    <Styled.Label>© 2022 Codex Software</Styled.Label>
                </Styled.IconContainer>
                <Styled.SocialMediaIcon onClick={onEmailClick} type={ICON_TYPES.emailLogo} />
            </Wrapper>
        </Styled.Footer>
    );
};

export default Footer;
