import { IconProps } from './icon.types';

const Email: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_982_1214)">
                <path
                    d="M80 29.8039C80 24.6109 75.7682 20.3921 70.5882 20.3921H56.4518C55.5854 20.3921 54.8831 21.0944 54.8831 21.9607C54.8831 22.8271 55.5854 23.5293 56.4518 23.5293H70.5882C71.4982 23.5293 72.3628 23.7256 73.144 24.0757C71.0549 25.6958 41.9297 48.2829 40 49.7795C38.0618 48.2765 8.93224 25.6861 6.85584 24.0757C7.63718 23.7256 8.5018 23.5293 9.41177 23.5293H23.5482C24.4146 23.5293 25.1169 22.8271 25.1169 21.9607C25.1169 21.0944 24.4146 20.3921 23.5482 20.3921H9.41177C4.23969 20.3921 0 24.6035 0 29.8039V65.8823C0 71.0384 4.19341 75.2941 9.41177 75.2941H70.5882C75.7991 75.2941 80 71.0465 80 65.8823V29.8039ZM50.8824 45.3102L75.6502 26.1022C76.4116 27.1406 76.8627 28.4203 76.8627 29.8039V65.8823C76.8627 66.7121 76.6993 67.5039 76.4055 68.2294L50.8824 45.3102ZM3.59451 68.2294C3.30071 67.5039 3.13725 66.7121 3.13725 65.8823V29.8039C3.13725 28.4203 3.58824 27.1408 4.3498 26.1022L29.1176 45.3102L3.59451 68.2294ZM9.41177 72.1568C7.92031 72.1568 6.54949 71.6329 5.47153 70.7606L31.6373 47.2642L39.0387 53.0042C39.6045 53.4429 40.3956 53.4429 40.9616 53.0042L48.363 47.2642L74.5286 70.7604C73.4505 71.6329 72.0797 72.1568 70.5882 72.1568H9.41177Z"
                    fill="#212529"
                />
                <path
                    d="M57.2549 64.3135H22.7451C21.8788 64.3135 21.1765 65.0159 21.1765 65.8821C21.1765 66.7483 21.8788 67.4507 22.7451 67.4507H57.2549C58.1211 67.4507 58.8236 66.7483 58.8236 65.8821C58.8236 65.0159 58.1211 64.3135 57.2549 64.3135Z"
                    fill="#212529"
                />
                <path
                    d="M27.451 61.1763H52.549C53.4152 61.1763 54.1176 60.4739 54.1176 59.6077C54.1176 58.7415 53.4152 58.0391 52.549 58.0391H27.451C26.5846 58.0391 25.8823 58.7415 25.8823 59.6077C25.8823 60.4739 26.5846 61.1763 27.451 61.1763Z"
                    fill="#212529"
                />
                <path
                    d="M40 40.7845C49.9628 40.7845 58.0392 32.7081 58.0392 22.7453C58.0392 12.7825 49.9628 4.70605 40 4.70605C30.0372 4.70605 21.9608 12.7825 21.9608 22.7453C21.9608 32.7081 30.0372 40.7845 40 40.7845Z"
                    fill="#A4FCDC"
                />
                <path
                    d="M34.9984 19.1193C35.8646 19.1193 36.5671 18.4169 36.5671 17.5507C36.5671 15.6133 38.1537 14.0813 40.0715 14.1185C41.861 14.1544 43.3354 15.5809 43.4279 17.3659C43.5308 19.3461 41.9589 20.9834 40 20.9834C39.1337 20.9834 38.4314 21.6859 38.4314 22.5521V26.6667C38.4314 27.5331 39.1337 28.2354 40 28.2354C40.8662 28.2354 41.5686 27.5331 41.5686 26.6667V23.9289C44.5257 23.1965 46.731 20.4773 46.5609 17.2032C46.3815 13.7445 43.4933 10.9805 40.0002 10.9805C36.3903 10.9805 33.4298 13.9024 33.4298 17.5507C33.4298 18.4169 34.1322 19.1193 34.9984 19.1193Z"
                    fill="#212529"
                />
                <path
                    d="M40 34.5098C40.8664 34.5098 41.5687 33.8075 41.5687 32.9412C41.5687 32.0749 40.8664 31.3726 40 31.3726C39.1337 31.3726 38.4314 32.0749 38.4314 32.9412C38.4314 33.8075 39.1337 34.5098 40 34.5098Z"
                    fill="#212529"
                />
            </g>
            <defs>
                <clipPath id="clip0_982_1214">
                    <rect width="80" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Email;
