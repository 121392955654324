import { Field, Form } from 'react-final-form';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './bespeak.styles';
import { emailService, FieldError, NavigateContext, ToastContext } from '../../../shared';
import validateEmail from '../../../shared/helpers/validateEmail';
import ReactGA from 'react-ga';
import PATH from '../../../shared/helpers/appRoutes';

enum FormValuesFields {
    email = 'email',
}

const Bespeak = () => {

    const navigate = useNavigate()
    const onSubmit = (value: { email: string }) => {
        return emailService.bespeak(value.email);
    };

    const { open } = useContext(ToastContext);
    const { bespeakRef } = useContext(NavigateContext);

    // const successNotification = () => {
    //     open({ type: 'Success', content: 'Your request was successfully sent!' });
    // };

    const errorNotification = () => {
        open({ type: 'Error', content: 'Something went wrong. Please try again!' });
    };

    return (
        <Styled.BespeakContainer ref={bespeakRef}>
            <Styled.Title type="h3">Order your custom bot today!</Styled.Title>
            <Styled.Description>Our manager will get in touch right away</Styled.Description>
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting, form }) => (
                    <form
                        onSubmit={(event) => {
                            const promise = handleSubmit(event);
                            if (promise) {
                                promise
                                    .then(() => {
                                        ReactGA.event({
                                            category: 'Form',
                                            action: "User filled a 'Bespeak a custom bot today form' and sent a request",
                                        });
                                        form.change(FormValuesFields.email, undefined);
                                        form.resetFieldState(FormValuesFields.email);
                                        navigate(PATH.thankYou);
                                    })
                                    .catch(() => errorNotification());
                                return promise;
                            }
                        }}
                    >
                        <Styled.TouchBlock>
                            <Field name={FormValuesFields.email} validate={validateEmail}>
                                {({ input, meta }) => (
                                    <Styled.FieldBlock>
                                        <Styled.FieldInput type="email" {...input} placeholder="Your email" />
                                        <FieldError meta={meta} />
                                    </Styled.FieldBlock>
                                )}
                            </Field>
                            <Styled.SubmitButton type="submit" disabled={submitting}>
                                <Styled.SubmitButtonText>Submit</Styled.SubmitButtonText>
                            </Styled.SubmitButton>
                        </Styled.TouchBlock>
                    </form>
                )}
            />
        </Styled.BespeakContainer>
    );
};

export default Bespeak;
