import * as Styled from './toast.styles';
import useTimeout from './useTimeout.hook';
import { Icon, ICON_TYPES } from '../icon';
import { Typography } from '../typography';

export type ToastType = 'Success' | 'Warning' | 'Error';

const getBackgroundColorByType = (type: ToastType) => {
    switch (type) {
        case 'Success':
            return '#A4FCDC';
        case 'Warning':
            return '#f4ff81';
        case 'Error':
            return '#ff9e80';
        default:
            throw new Error('unknown type');
    }
};

const getBorderColorByType = (type: ToastType) => {
    switch (type) {
        case 'Success':
            return '#1de9b6';
        case 'Warning':
            return '#ffea00';
        case 'Error':
            return '#ff3d00';
        default:
            throw new Error('unknown type');
    }
};

interface ToastProps {
    children: React.ReactNode;
    close: () => void;
    type: ToastType;
}

const Toast: React.FC<ToastProps> = ({ children, close, type = 'Success' }) => {
    useTimeout(close, 3000);

    const backgroundColor = getBackgroundColorByType(type);
    const borderColor = getBorderColorByType(type);

    return (
        <Styled.Toast backgroundColor={backgroundColor} borderColor={borderColor}>
            <Typography type="h6">{type}!</Typography>
            <Typography>{children}</Typography>
            <Styled.ButtonClose onClick={close}>
                <Icon type={ICON_TYPES.close} />
            </Styled.ButtonClose>
        </Styled.Toast>
    );
};

export default Toast;
