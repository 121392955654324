import { IconProps } from './icon.types';

const PieChart: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_982_1243)">
                <path
                    d="M120 57.637H62.363V0H64.9219C95.3616 0 120 24.6338 120 55.0781V57.637Z"
                    fill="url(#paint0_linear_982_1243)"
                    fill-opacity="0.7"
                />
                <path
                    d="M110.596 75.306H109.566C110.241 71.8276 110.587 68.2754 110.587 64.7066C110.587 63.4124 109.537 62.3629 108.243 62.3629H57.637V11.7568C57.637 10.4624 56.5877 9.41309 55.2933 9.41309C24.7355 9.41309 0 34.1441 0 64.7066C0 95.2361 24.7298 120 55.2933 120C63.907 120 72.3736 117.995 80.0126 114.176V117.656C80.0126 118.95 81.0621 120 82.3563 120H110.596C111.89 120 112.94 118.95 112.94 117.656V77.6497C112.94 76.3553 111.891 75.306 110.596 75.306ZM52.9495 14.1543V63.7358L17.8894 98.796C9.35789 89.4552 4.6875 77.4402 4.6875 64.7066C4.6875 37.5875 26.1286 15.3824 52.9495 14.1543ZM21.2055 102.109L56.2641 67.0504H105.846C105.718 69.8345 105.36 72.5971 104.784 75.306H101.183C99.8887 75.306 98.8392 76.3555 98.8392 77.6497V82.3661H91.7698C90.4753 82.3661 89.426 83.4156 89.426 84.7098V89.4259H82.3566C81.0623 89.4259 80.0128 90.4752 80.0128 91.7696V96.486H62.6252L56.213 83.6617C55.6341 82.5041 54.2264 82.0347 53.0686 82.6133C51.9108 83.1922 51.4416 84.6001 52.0205 85.7577L59.0805 99.8776C59.4776 100.672 60.289 101.173 61.1768 101.173H80.0128V108.872C61.5307 119.234 37.7112 117.184 21.2055 102.109ZM84.7003 94.1134H89.426V115.312H84.7003V94.1134ZM94.1135 87.0536H98.8392V115.312H94.1135C94.1135 109.523 94.1135 93.4271 94.1135 87.0536ZM108.253 115.312H103.527C103.527 109.968 103.527 85.3262 103.527 79.9935H108.253V115.312Z"
                    fill="#212529"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_982_1243"
                    x1="100.757"
                    y1="4.34397"
                    x2="118.035"
                    y2="8.15903"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#E2C4FF" />
                    <stop offset="1" stop-color="#E9D2FF" />
                </linearGradient>
                <clipPath id="clip0_982_1243">
                    <rect width="120" height="120" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default PieChart;
