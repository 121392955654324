import styled from 'styled-components';
import convertSize from '../../helpers/convertSize';

export const ButtonContainer = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #171a1d;
    color: #ffffff;
    border-radius: ${convertSize(8)};
    padding: 0 ${convertSize(15)};
    border: none;
    margin: 0;
    min-height: ${convertSize(40)};
    cursor: pointer;
    width: ${convertSize(200)};

    @media (max-width: 1440px) {
        border-radius: 8px;
        padding: 0 15px;
        min-height: 40px;
        width: 200px;
    }

    &:disabled {
        opacity: 0.7;
    }

    &:hover {
        background-color: #070d13;
    }
`;

export const OutlinedContainer = styled(ButtonContainer)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: ${convertSize(1)} solid #ffffff;

    &:hover {
        background-color: transparent;
        border: ${convertSize(1)} solid #bfbfbf;
    }

    @media (max-width: 1440px) {
        border: 1px solid #ffffff;

        &:hover {
            background-color: transparent;
            border: 1px solid #ffffff;
        }
    }
`;
