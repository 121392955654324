import { Form, Field } from 'react-final-form';
import { useContext } from 'react';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';

import * as Styled from './right-content.styles';
import { composeValidators, fieldRequired, maxLength } from './helpers';
import validateEmail from '../../../../../shared/helpers/validateEmail';
import { emailService, FieldError, NavigateContext, ToastContext } from '../../../../../shared';
import PATH from '../../../../../shared/helpers/appRoutes';

enum FormValuesFields {
    name = 'name',
    email = 'email',
    description = 'description',
}

type FormValuesType = {
    [FormValuesFields.name]: string;
    [FormValuesFields.email]: string;
    [FormValuesFields.description]: string;
};

const fields = [
    {
        name: FormValuesFields.name,
        placeholder: 'Name',
        type: 'text',
        validate: fieldRequired,
    },
    {
        name: FormValuesFields.email,
        placeholder: 'Your email',
        type: 'email',
        validate: composeValidators(fieldRequired, validateEmail),
    },
    {
        name: FormValuesFields.description,
        placeholder: 'Briefly describe your question',
        type: 'text',
        validate: maxLength(500),
    },
];

const RightContent = () => {

    const navigate = useNavigate()
    const { contactUsRef } = useContext(NavigateContext);
    const onSubmit = (value: FormValuesType) => {
        return emailService.consultation(value);
    };

    const { open } = useContext(ToastContext);

    // const successNotification = () => {
    //     open({ type: 'Success', content: 'Your request was successfully sent!' });
    // };

    const errorNotification = () => {
        open({ type: 'Error', content: 'Something went wrong. Please try again!' });
    };

    return (
        <Styled.RightSide ref={contactUsRef}>
            <Styled.Title>Free Consultation</Styled.Title>
            <Styled.Description type="regular-text">Planning a custom chatbot?</Styled.Description>
            <Styled.Description type="regular-text">Arrange a 15-min call with an expert.</Styled.Description>
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting, form }) => (
                    <form
                        onSubmit={(event) => {
                            const promise = handleSubmit(event);
                            if (promise) {
                                promise
                                    .then(() => {
                                        ReactGA.event({
                                            category: 'Form',
                                            action: "User filled a 'Free consultation form' and sent a request",
                                        });
                                        (Object.keys(FormValuesFields) as Array<string>).forEach((key) => {
                                            const fieldKey = key as keyof FormValuesType;
                                            form.change(fieldKey, undefined);
                                            form.resetFieldState(fieldKey);
                                        });
                                        navigate(PATH.thankYou);
                                    })
                                    .catch(() => errorNotification());
                                return promise;
                            }
                        }}
                    >
                        {fields.map(({ name, validate, type, placeholder }) => (
                            <Field name={name} validate={validate} key={name}>
                                {({ input, meta }) => (
                                    <Styled.FieldBlock>
                                        <Styled.FieldInput type={type} {...input} placeholder={placeholder} />
                                        <FieldError meta={meta} />
                                    </Styled.FieldBlock>
                                )}
                            </Field>
                        ))}

                        <Styled.SubmitButton type="submit" disabled={submitting}>
                            <Styled.SubmitButtonText>Submit</Styled.SubmitButtonText>
                        </Styled.SubmitButton>
                    </form>
                )}
            />
        </Styled.RightSide>
    );
};

export default RightContent;
