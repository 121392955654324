import { IconProps } from './icon.types';

const Scissors: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_619_1363)">
                <path
                    d="M67.2476 68.0447L70.0129 69.3876L93.4014 21.4777C97.2955 13.5 93.9686 3.88432 85.97 0L60 53.1985L64.4698 62.3545L67.2476 68.0447Z"
                    fill="#B2F0FB"
                />
                <path
                    d="M34.0298 0L64.4696 62.3545L49.9871 69.3873L26.5989 21.4774C22.7045 13.5 26.0314 3.88432 34.0298 0V0Z"
                    fill="#B2F0FB"
                />
                <path
                    d="M50.2899 84.8098L38.0693 78.8753C30.1708 75.0395 20.6151 78.3296 16.7686 86.2094C14.9084 90.0196 14.6473 94.3301 16.0335 98.3463C17.4223 102.371 20.2947 105.611 24.1216 107.469C26.3603 108.556 28.7321 109.071 31.0694 109.071C36.9782 109.071 42.6661 105.781 45.4223 100.135L51.3723 87.9464C51.6454 87.3869 51.6848 86.7419 51.4818 86.1538C51.2788 85.5652 50.8499 85.0817 50.2899 84.8098ZM41.2099 98.0784C38.4949 103.64 31.7485 105.961 26.1695 103.252C23.4705 101.941 21.4449 99.6561 20.4649 96.8172C19.488 93.986 19.6712 90.949 20.9813 88.2657C22.9265 84.2813 26.9409 81.9601 31.1126 81.9601C32.7638 81.9601 34.4397 82.3239 36.0216 83.092L46.1295 88.0006L41.2099 98.0784Z"
                    fill="#212529"
                />
                <path
                    d="M113.084 81.4248C106.592 68.1264 90.4642 62.5737 77.133 69.0482L71.6464 71.7128L66.5758 61.3262C66.0088 60.1644 64.6077 59.681 63.4458 60.2462L48.9633 67.279C48.4033 67.5509 47.9744 68.0345 47.7714 68.6228C47.5684 69.2111 47.6078 69.8562 47.8808 70.4154L48.4338 71.5482L48.3534 71.7128L42.8668 69.0482C29.5353 62.5739 13.4076 68.1264 6.91571 81.4248C3.77323 87.8621 3.32906 95.1354 5.66547 101.906C8.00656 108.69 12.8575 114.156 19.3241 117.296C23.1037 119.132 27.1059 120 31.0518 120C41.0238 120 50.6235 114.448 55.2749 104.92L59.9998 95.2409L64.7246 104.92C69.3763 114.449 78.9749 120 88.9477 120C92.8927 120 96.8968 119.131 100.675 117.296C107.142 114.156 111.993 108.69 114.334 101.906C116.671 95.1354 116.227 87.8621 113.084 81.4248ZM57.3917 89.8982L51.0625 102.863C45.702 113.843 32.3829 118.426 21.372 113.079C16.0333 110.486 12.0292 105.975 10.0969 100.376C8.16946 94.7916 8.53605 88.7914 11.1282 83.4811C14.9689 75.614 22.8941 71.0312 31.1301 71.0312C34.3889 71.0312 37.6973 71.7491 40.8189 73.2649L48.4096 76.9514C49.26 77.3649 50.2391 77.217 50.9249 76.6519L57.3917 89.8982ZM109.903 100.376C107.971 105.975 103.967 110.487 98.6278 113.079C87.6166 118.426 74.2974 113.843 68.9373 102.863L53.1483 70.52L53.1474 70.5181L53.1239 70.4698L63.3895 65.4845L68.4601 75.8711C69.0273 77.033 70.428 77.5163 71.5902 76.9512L79.1809 73.2647C90.1919 67.9173 103.511 72.5003 108.872 83.4808C111.464 88.7914 111.83 94.7916 109.903 100.376Z"
                    fill="#212529"
                />
                <path
                    d="M81.9305 78.8753L69.7099 84.8098C69.1499 85.0817 68.721 85.5653 68.518 86.1536C68.315 86.7419 68.3544 87.3869 68.6275 87.9462L74.5775 100.135C77.3344 105.782 83.0212 109.071 88.9304 109.071C91.2673 109.071 93.64 108.556 95.8782 107.469C99.7051 105.611 102.578 102.371 103.966 98.3461C105.352 94.3296 105.091 90.0194 103.231 86.2091C99.3844 78.3296 89.8295 75.0395 81.9305 78.8753ZM99.5351 96.8174C98.5552 99.6563 96.5296 101.942 93.8305 103.252C92.2484 104.02 90.5732 104.384 88.9217 104.384C84.7503 104.384 80.7354 102.063 78.7902 98.0787L73.8706 88.0008L83.9784 83.0922C89.5569 80.3836 96.3038 82.7045 99.0188 88.2659C100.329 90.949 100.512 93.986 99.5351 96.8174Z"
                    fill="#212529"
                />
            </g>
            <defs>
                <clipPath id="clip0_619_1363">
                    <rect width="120" height="120" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Scissors;
