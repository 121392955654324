import styled from 'styled-components';
import convertSize from '../../../shared/helpers/convertSize';

export const FeaturesContainer = styled.section`
    padding-top: ${convertSize(150)};
    background-color: #f5f5f5;

    @media (max-width: 1440px) {
        padding-top: 150px;
    }

    @media (max-width: 1240px) {
        padding: 150px 20px 0 20px;
        box-sizing: border-box;
    }

    @media (max-width: 479px) {
        padding: 150px 0 0;
    }
`;

export const FeatureContent = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: ${convertSize(1200)};
    width: auto;
    margin: 0 auto;

    @media (max-width: 1440px) {
        max-width: 1200px;
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
    }
`;
