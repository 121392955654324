import { IconProps } from './icon.types';

const Discussion: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M74.1717 29.8091H47.0313C46.1684 29.8091 45.4688 30.5086 45.4688 31.3716C45.4688 32.2346 46.1684 32.9341 47.0313 32.9341H74.1717C75.6623 32.9341 76.875 34.1467 76.875 35.6374V61.6211C76.875 63.1117 75.6623 64.3244 74.1717 64.3244C73.6277 64.3244 73.1225 64.6074 72.8388 65.0717L70.3339 69.1678L67.8281 65.0714C67.5444 64.6074 67.0394 64.3244 66.4953 64.3244H38.7747C37.2841 64.3244 36.0714 63.1117 36.0714 61.6211V43.9067C36.0714 43.0439 35.3717 42.3442 34.5089 42.3442C33.6461 42.3442 32.9464 43.0439 32.9464 43.9067V61.6211C32.9464 64.8347 35.5609 67.4494 38.7747 67.4494H65.6194L69.0014 72.978C69.61 73.9725 71.0584 73.9735 71.6672 72.9777L75.0922 67.3767C77.8702 66.9342 80 64.5217 80 61.6211V35.6374C80 32.4236 77.3855 29.8091 74.1717 29.8091Z" fill="#171A1D"/>
        <path d="M70.5931 41.563C71.4559 41.563 72.1556 40.8633 72.1556 40.0005C72.1556 39.1375 71.4559 38.438 70.5931 38.438H51.7667C50.9039 38.438 50.2042 39.1375 50.2042 40.0005C50.2042 40.8633 50.9039 41.563 51.7667 41.563H70.5931Z" fill="#171A1D"/>
        <path d="M42.3531 50.1919H70.593C71.4558 50.1919 72.1555 49.4922 72.1555 48.6294C72.1555 47.7666 71.4558 47.0669 70.593 47.0669H42.3531C41.4903 47.0669 40.7906 47.7666 40.7906 48.6294C40.7906 49.4922 41.4903 50.1919 42.3531 50.1919Z" fill="#171A1D"/>
        <path d="M42.3531 58.8208H70.593C71.4558 58.8208 72.1555 58.1211 72.1555 57.2583C72.1555 56.3955 71.4558 55.6958 70.593 55.6958H42.3531C41.4903 55.6958 40.7906 56.3955 40.7906 57.2583C40.7906 58.1213 41.4903 58.8208 42.3531 58.8208Z" fill="#171A1D"/>
        <path d="M4.90766 43.8437C2.12969 43.4012 0 40.9888 0 38.0884V12.1037C0 8.89025 2.61438 6.27588 5.82797 6.27588H41.2256C44.4391 6.27588 47.0536 8.89025 47.0536 12.1037V38.0884C47.0536 41.302 44.4392 43.9163 41.2256 43.9163H14.3803L9.66625 51.624L4.90766 43.8437Z" fill="#B3F5FE"/>
        <path d="M32.8014 26.6585H34.5089C35.3717 26.6585 36.0714 25.9589 36.0714 25.096C36.0714 24.233 35.3717 23.5335 34.5089 23.5335H32.8014C32.1401 19.5964 29.0264 16.4825 25.0894 15.8213V14.1138C25.0894 13.2508 24.3897 12.5513 23.5269 12.5513C22.6641 12.5513 21.9644 13.2508 21.9644 14.1138V15.8213C18.0272 16.4825 14.9133 19.5964 14.252 23.5335H12.5447C11.6819 23.5335 10.9822 24.233 10.9822 25.096C10.9822 25.9589 11.6819 26.6585 12.5447 26.6585H14.252C14.9133 30.5955 18.0272 33.7094 21.9644 34.3706V36.0781C21.9644 36.9411 22.6641 37.6406 23.5269 37.6406C24.3897 37.6406 25.0894 36.9411 25.0894 36.0781V34.3706C29.0264 33.7094 32.1401 30.5955 32.8014 26.6585ZM25.0894 31.1811V29.8027C25.0894 28.9397 24.3897 28.2402 23.5269 28.2402C22.6641 28.2402 21.9644 28.9397 21.9644 29.8027V31.1811C19.7537 30.6131 18.0097 28.8692 17.4417 26.6585H18.8201C19.683 26.6585 20.3826 25.9589 20.3826 25.096C20.3826 24.233 19.683 23.5335 18.8201 23.5335H17.4417C18.0097 21.3228 19.7537 19.5788 21.9644 19.011V20.3894C21.9644 21.2524 22.6641 21.9519 23.5269 21.9519C24.3897 21.9519 25.0894 21.2524 25.0894 20.3894V19.011C27.3 19.5789 29.0439 21.3228 29.6117 23.5335H28.2334C27.3706 23.5335 26.6709 24.233 26.6709 25.096C26.6709 25.9589 27.3706 26.6585 28.2334 26.6585H29.6117C29.0439 28.8691 27.3 30.613 25.0894 31.1811Z" fill="#171A1D"/>
        </svg>
    );
};

export default Discussion;
