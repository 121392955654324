import { IconProps } from './icon.types';

const Chat: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M120 46.5844C120 25.1426 102.626 7.76062 81.1943 7.76062C64.7505 7.76062 50.6956 17.9934 45.0441 32.4412C64.6472 34.3957 79.9552 50.9451 79.9552 71.0719C79.9552 76.0622 79.0139 80.8324 77.2997 85.215C78.5806 85.3427 79.8797 85.4084 81.1943 85.4084C88.353 85.4084 95.059 83.4689 100.816 80.0866L109.426 82.0036L108.108 74.5537C115.439 67.4909 120 57.5707 120 46.5844Z"
                fill="#B2F0FB"
            />
            <path
                d="M41.1495 29.9042C18.4596 29.9042 0 48.372 0 71.0719C0 81.941 4.15031 92.1075 11.7143 99.8403L10.6095 106.082C10.4728 106.854 10.7309 107.642 11.2969 108.184C11.8629 108.726 12.6626 108.949 13.4266 108.779L21.1514 107.059C27.2416 110.451 34.1348 112.24 41.1495 112.24C63.8395 112.24 82.2989 93.7721 82.2989 71.0721C82.2989 48.3722 63.8395 29.9042 41.1495 29.9042ZM41.1495 107.552C34.6559 107.552 28.2813 105.823 22.7147 102.553C22.3521 102.34 21.942 102.23 21.5276 102.23C21.3574 102.23 21.1866 102.248 21.0183 102.286L15.8377 103.439L16.5438 99.4493C16.68 98.6806 16.4243 97.8945 15.862 97.3528C8.65617 90.4109 4.6875 81.0774 4.6875 71.0719C4.6875 50.9567 21.0443 34.5919 41.1495 34.5919C61.2546 34.5919 77.6114 50.9569 77.6114 71.0719C77.6114 91.1869 61.2548 107.552 41.1495 107.552Z"
                fill="#212529"
            />
            <path
                d="M68.1223 71.5323L61.436 57.4466C62.6301 57.3387 63.5662 56.3349 63.5662 55.1127C63.5662 53.8182 62.5167 52.7689 61.2225 52.7689H43.4932V52.0126C43.4932 50.7181 42.4439 49.6688 41.1494 49.6688C39.855 49.6688 38.8057 50.7181 38.8057 52.0126V52.7689H21.0764C19.7819 52.7689 18.7326 53.8182 18.7326 55.1127C18.7326 56.3352 19.6685 57.3387 20.8629 57.4466L14.1769 71.5323C13.7519 72.4276 13.9359 73.4933 14.6365 74.1941C17.2507 76.8095 20.7265 78.2499 24.424 78.2499C28.1212 78.2499 31.597 76.8095 34.2115 74.1941C34.912 73.4933 35.096 72.4273 34.6711 71.5323L27.9895 57.4562H38.8059V79.4066H36.4296C35.1351 79.4066 34.0858 80.4559 34.0858 81.7503C34.0858 83.0448 35.1351 84.0941 36.4296 84.0941H38.8059V88.2659H26.546C25.2516 88.2659 24.2023 89.3152 24.2023 90.6097C24.2023 91.9041 25.2516 92.9534 26.546 92.9534H55.7531C57.0476 92.9534 58.0969 91.9041 58.0969 90.6097C58.0969 89.3152 57.0476 88.2659 55.7531 88.2659H43.4932V84.0941H45.8695C47.164 84.0941 48.2133 83.0448 48.2133 81.7503C48.2133 80.4559 47.164 79.4066 45.8695 79.4066H43.4932V57.4562H54.3094L47.6278 71.5323C47.2029 72.4276 47.3869 73.4933 48.0874 74.1941C50.7016 76.8097 54.1776 78.2499 57.8749 78.2499C61.5722 78.2499 65.0482 76.8095 67.6622 74.1941C68.3632 73.4933 68.5472 72.4276 68.1223 71.5323ZM24.4237 73.5627C22.522 73.5627 20.7087 72.9863 19.1833 71.9162L24.4237 60.8759L29.6641 71.9162C28.1388 72.9861 26.3257 73.5627 24.4237 73.5627ZM57.8751 73.5627C55.9732 73.5627 54.1601 72.9863 52.6345 71.9162L57.8749 60.8759L63.1153 71.9162C61.5902 72.9863 59.7769 73.5627 57.8751 73.5627Z"
                fill="#212529"
            />
        </svg>
    );
};

export default Chat;
