import { IconProps } from './icon.types';

const Document: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M111.302 0H33.6473C32.3529 0 31.3036 1.0493 31.3036 2.34375V49.4123C31.3036 50.7066 32.3529 51.7561 33.6473 51.7561C34.9418 51.7561 35.9911 50.7066 35.9911 49.4123V4.6875H108.959V115.312H51.2255C49.931 115.312 48.8817 116.362 48.8817 117.656C48.8817 118.95 49.931 120 51.2255 120H111.302C112.597 120 113.646 118.95 113.646 117.656V2.34375C113.646 1.0493 112.597 0 111.302 0Z"
                fill="#212529"
            />
            <path
                d="M47.7628 18.8076H97.1822C98.4764 18.8076 99.5259 17.7581 99.5259 16.4639C99.5259 15.1696 98.4764 14.1201 97.1822 14.1201H47.7628C46.4684 14.1201 45.4191 15.1696 45.4191 16.4639C45.4191 17.7583 46.4686 18.8076 47.7628 18.8076Z"
                fill="#212529"
            />
            <path
                d="M47.7628 32.9272H97.1822C98.4764 32.9272 99.5259 31.8777 99.5259 30.5835C99.5259 29.2893 98.4764 28.2397 97.1822 28.2397H47.7628C46.4684 28.2397 45.4191 29.2893 45.4191 30.5835C45.4191 31.878 46.4686 32.9272 47.7628 32.9272Z"
                fill="#212529"
            />
            <path
                d="M47.7628 47.0469H97.1822C98.4764 47.0469 99.5259 45.9973 99.5259 44.7031C99.5259 43.4087 98.4764 42.3594 97.1822 42.3594H47.7628C46.4684 42.3594 45.4191 43.4087 45.4191 44.7031C45.4191 45.9976 46.4686 47.0469 47.7628 47.0469Z"
                fill="#212529"
            />
            <path
                d="M97.1824 56.4795H61.8825C60.5883 56.4795 59.5388 57.5288 59.5388 58.8232C59.5388 60.1177 60.5883 61.167 61.8825 61.167H97.1822C98.4764 61.167 99.5259 60.1177 99.5259 58.8232C99.5259 57.5288 98.4766 56.4795 97.1824 56.4795Z"
                fill="#212529"
            />
            <path
                d="M97.1824 70.5996H61.8825C60.5883 70.5996 59.5388 71.6489 59.5388 72.9434C59.5388 74.2376 60.5883 75.2871 61.8825 75.2871H97.1822C98.4764 75.2871 99.5259 74.2376 99.5259 72.9434C99.5262 71.6489 98.4766 70.5996 97.1824 70.5996Z"
                fill="#212529"
            />
            <path
                d="M88.9458 105.88C83.1117 105.88 78.3654 101.134 78.3654 95.2996C78.3654 89.4656 83.1117 84.7192 88.9458 84.7192C94.7799 84.7192 99.5259 89.4656 99.5259 95.2996C99.5259 101.134 94.7799 105.88 88.9458 105.88Z"
                fill="#FFBEBC"
            />
            <path
                d="M47.5273 63.5299C47.5273 55.8117 41.3346 49.54 33.6469 49.4121C25.8699 49.2829 19.2909 55.7456 19.2874 63.5238C19.2863 66.0171 19.9315 68.3589 21.0647 70.3921C28.4956 83.7234 26.2983 95.6432 26.3473 96.4767H40.4672L40.3479 95.0458C39.6305 86.4349 41.6541 77.8764 45.8025 70.2967C46.9022 68.2875 47.5273 65.9819 47.5273 63.5299Z"
                fill="#FFBEBC"
            />
            <path
                d="M58.1175 94.1328H8.69766C7.40321 94.1328 6.35391 95.1823 6.35391 96.4766V108.243C6.35391 109.537 7.40344 110.587 8.69766 110.587H13.4138V117.656C13.4138 118.951 14.4633 120 15.7575 120H51.0572C52.3516 120 53.4009 118.951 53.4009 117.656V110.587H58.1173C59.4115 110.587 60.461 109.537 60.461 108.243V96.4766C60.4613 95.1821 59.4117 94.1328 58.1175 94.1328ZM48.7137 115.313H18.1013V110.587H48.7134V115.313H48.7137ZM55.7738 105.899C50.7631 105.899 16.6059 105.899 11.0416 105.899V98.8203H55.7738V105.899Z"
                fill="#212529"
            />
        </svg>
    );
};

export default Document;
