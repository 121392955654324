import * as Styled from './companies.styles';

import axiell from '../../../assets/company-icons/axiell.png';
import cultureConnect from '../../../assets/company-icons/cultureConnect.png';
import hp from '../../../assets/company-icons/hp.png';
import macmillian from '../../../assets/company-icons/macmillian.png';
import oneFlow from '../../../assets/company-icons/oneFlow.png';
import pronoun from '../../../assets/company-icons/pronoun.png';

const icons = [
    {icon:oneFlow, width:136, height:36},
    {icon:hp, width:48, height:48},
    {icon:macmillian, width:206, height:30},
    {icon:pronoun, width:194, height:42},
    {icon:cultureConnect, width:235, height:38},
    {icon:axiell, width:86, height:42},
];

const Companies = () => {
    return (
        <Styled.CompaniesContainer>
            <Styled.CompaniesContent>
                {icons.map(({icon, height, width}, index) => (
                    <Styled.CompanyIconBlock key={index}>
                        <Styled.CompanyIcon height={height} width={width} src={icon} alt="company-logo" />
                    </Styled.CompanyIconBlock>
                ))}
            </Styled.CompaniesContent>
        </Styled.CompaniesContainer>
    );
};

export default Companies;
