import { IconProps } from './icon.types';

const Responsive: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_982_1171)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M61.0649 26.9321L53.74 31.1613C51.5058 32.4543 48.7493 30.8093 48.7502 28.2907V19.731C48.7502 17.2757 51.4705 15.4161 53.9513 16.9864L61.1605 21.1402C63.4077 22.4411 63.3496 25.7074 61.0649 26.9321Z"
                    fill="#A4FCDC"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.72668 35.8589H32.7736V51.2234H9.72668V35.8589Z"
                    fill="#A4FCDC"
                />
                <path
                    d="M75.3127 11.2497H72.1875V4.68749C72.1875 2.10281 70.0849 0 67.5002 0H44.0626C41.4779 0 39.3751 2.10281 39.3751 4.68749V11.2497H4.68749C2.10281 11.2497 0 13.3525 0 15.9372V62.8122C0 65.3969 2.10281 67.4997 4.68749 67.4997H30.6063L29.2746 76.8752H27.5001C26.6371 76.8752 25.9376 77.5747 25.9376 78.4377C25.9376 79.3006 26.6371 80.0002 27.5001 80.0002H52.5001C53.3629 80.0002 54.0625 79.3006 54.0625 78.4377C54.0625 77.5747 53.3629 76.8752 52.5001 76.8752H50.7255L49.3938 67.4997H75.3128C77.8975 67.4997 80.0002 65.3969 80.0002 62.8122V15.9372C80 13.3525 77.8973 11.2497 75.3127 11.2497ZM42.5001 4.68749C42.5001 3.82593 43.201 3.12499 44.0626 3.12499H47.969C47.969 3.98796 48.6687 4.68749 49.5315 4.68749H62.0314C62.8943 4.68749 63.5939 3.98796 63.5939 3.12499H67.5002C68.3617 3.12499 69.0625 3.82593 69.0625 4.68749V48.0987C69.0625 48.9602 68.3617 49.6612 67.5002 49.6612H44.0626C43.201 49.6612 42.5001 48.9602 42.5001 48.0987V4.68749ZM4.68749 14.3747H39.3751V48.0985C39.3751 50.6832 41.4779 52.786 44.0626 52.786H67.5002C70.0849 52.786 72.1875 50.6832 72.1875 48.0985V14.3747H75.3127C76.1742 14.3747 76.875 15.0756 76.875 15.9372V57.3433H3.12499V15.9372C3.12499 15.0756 3.82593 14.3747 4.68749 14.3747ZM47.5693 76.875H32.431L33.7627 67.4995H46.2374L47.5693 76.875ZM75.3127 64.3747H4.68749C3.82593 64.3747 3.12499 63.6738 3.12499 62.8122V60.4683H76.875V62.8122C76.875 63.6738 76.1742 64.3747 75.3127 64.3747Z"
                    fill="#212529"
                />
                <path
                    d="M18.4391 28.8787C18.6852 29.0208 18.9537 29.0884 19.2189 29.0884C19.7589 29.0884 20.2841 28.8081 20.5734 28.3068L24.633 21.2756C25.0645 20.5283 24.8084 19.5726 24.0611 19.1412C23.3139 18.7098 22.3583 18.9658 21.9267 19.7131L17.8672 26.7443C17.4358 27.4917 17.6917 28.4473 18.4391 28.8787Z"
                    fill="#212529"
                />
                <path
                    d="M12.1375 28.6306C12.4426 28.9357 12.8425 29.0882 13.2423 29.0882C13.6422 29.0882 14.0422 28.9357 14.3472 28.6306C14.9573 28.0204 14.9573 27.031 14.3472 26.4207L11.9364 24.0099L14.3472 21.5992C14.9573 20.989 14.9573 19.9996 14.3472 19.3893C13.737 18.779 12.7476 18.7792 12.1373 19.3893L8.62171 22.9049C8.01155 23.5151 8.01155 24.5045 8.62171 25.1148L12.1375 28.6306Z"
                    fill="#212529"
                />
                <path
                    d="M28.1529 28.6304C28.4581 28.9355 28.8579 29.088 29.2578 29.088C29.6576 29.088 30.0576 28.9355 30.3626 28.6304L33.8782 25.1147C34.4884 24.5046 34.4884 23.5152 33.8782 22.9049L30.3626 19.3893C29.7524 18.7791 28.7631 18.7791 28.1528 19.3893C27.5424 19.9994 27.5426 20.9888 28.1528 21.5991L30.5635 24.0099L28.1528 26.4207C27.5428 27.0308 27.5428 28.0202 28.1529 28.6304Z"
                    fill="#212529"
                />
                <path
                    d="M16.5626 45.1035H25.9376C26.8006 45.1035 27.5001 44.404 27.5001 43.541C27.5001 42.678 26.8006 41.9785 25.9376 41.9785H16.5626C15.6997 41.9785 15.0001 42.678 15.0001 43.541C15.0001 44.404 15.6997 45.1035 16.5626 45.1035Z"
                    fill="#212529"
                />
                <path
                    d="M52.6563 45.1035H58.9063C59.7691 45.1035 60.4688 44.404 60.4688 43.541C60.4688 42.678 59.7691 41.9785 58.9063 41.9785H52.6563C51.7935 41.9785 51.0938 42.678 51.0938 43.541C51.0938 44.404 51.7935 45.1035 52.6563 45.1035Z"
                    fill="#212529"
                />
            </g>
            <defs>
                <clipPath id="clip0_982_1171">
                    <rect width="80" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Responsive;
