import styled from 'styled-components';
import { Icon, Typography } from '../../../shared';
import background2 from '../../../assets/background/background2.png';
import convertSize from '../../../shared/helpers/convertSize';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${convertSize(150)};
    background-color: #f5f5f5;

    @media (max-width: 1440px) {
        padding-top: 150px;
    }

    @media (max-width: 1240px) {
        padding: 150px 20px 0 20px;
        box-sizing: border-box;
    }
`;
export const TitleBlock = styled.div`
    width: ${convertSize(760)};
    text-align: center;
    margin-bottom: ${convertSize(32)};

    @media (max-width: 1440px) {
        width: 760px;
        margin-bottom: 32px;
    }

    @media (max-width: 767px) {
        width: auto;
    }
`;

export const Cards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: ${convertSize(24)};
    max-width: ${convertSize(1200)};
    width: auto;
    height: 100%;
    margin: 0 auto;

    @media (max-width: 1440px) {
        grid-gap: 24px;
        max-width: 1200px;
    }

    @media (max-width: 1240px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
`;

export const Card = styled.div`
    background-color: #ffffff;
    border-radius: ${convertSize(8)};
    width: ${convertSize(384)};
    height: ${convertSize(384)};
    padding: ${convertSize(32)};
    box-sizing: border-box;
    box-shadow: 0 ${convertSize(24)} ${convertSize(48)} rgba(0, 0, 0, 0.12);

    @media (max-width: 1440px) {
        border-radius: 8px;
        width: 384px;
        height: 384px;
        padding: 32px;
        box-shadow: 0 24px 48px rgba(0, 0, 0, 0.12);
    }

    @media (max-width: 1240px) {
        width: 335px;
    }
`;

export const CardTitle = styled(Typography)`
    margin-top: ${convertSize(24)};

    @media (max-width: 1440px) {
        margin-top: 24px;
    }
`;

export const CardDescription = styled(CardTitle)`
    margin-top: ${convertSize(12)};
    width: ${convertSize(320)};
    letter-spacing: 0;

    @media (max-width: 1440px) {
        margin-top: 12px;
        width: 320px;
    }

    @media (max-width: 1240px) {
        width: auto;
    }
`;

export const LastCard = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: url(${background2});
    background-size: cover;
    box-shadow: none;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: translate(3px, -3px);
    }

    @media (max-width: 1240px) {
        width: 335px;
    }
`;

export const LastCardTitle = styled(CardDescription)`
    margin-top: 0;
    margin-bottom: ${convertSize(24)};
    width: ${convertSize(260)};
    font-weight: 500;
    font-size: ${convertSize(32)};
    line-height: ${convertSize(38)};
    color: #ffffff;

    @media (max-width: 1440px) {
        margin-bottom: 24px;
        width: 260px;
        font-size: 32px;
        line-height: 38px;
    }
`;

export const StyledIcon = styled(Icon)`
    width: ${convertSize(48)};
    height: ${convertSize(48)};

    @media (max-width: 1440px) {
        width: 48px;
        height: 48px;
    }
`;
