import { IconProps } from './icon.types';

const CodexLogo: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M27.0465 16.3334C26.9225 16.1258 26.6333 16.0843 26.4268 16.2089L23.6587 17.9935C24.2371 18.9066 24.5676 19.9443 24.5676 21.1064C24.5676 24.2607 22.0061 26.834 18.8662 26.834C15.7263 26.834 13.1648 24.2607 13.1648 21.1064C13.1648 17.952 15.7263 15.3788 18.8662 15.3788C19.6099 15.3788 20.3122 15.5033 20.9732 15.7938L18.8662 21.1479L21.9648 16.3334L23.7413 13.5941C23.8653 13.3866 23.8239 13.096 23.6174 12.9715C23.2042 12.7225 22.7911 12.4735 22.3779 12.2659C17.8333 9.98319 12.2146 10.8133 8.53757 14.5487C3.82772 19.1557 3.82772 26.7925 8.49626 31.4825C13.1648 36.1725 20.7667 36.1725 25.4765 31.4825C29.5667 27.3736 30.1038 21.0234 27.0465 16.3334Z"
                fill="white"
            />
            <path
                d="M35 5C32.6996 5.45455 30.3156 6.59091 28.308 8.40909C27.8061 8.86364 27.346 9.31818 26.9696 9.81061L25.0038 9.58333L24 10.4924C24.9202 10.8712 25.8403 11.4394 26.6768 12.1591L26.2586 12.5379L26.7186 12.9545C27.9734 11.8182 28.6008 11.25 29.6882 10.2652L27.4715 12.9545C28.0989 13.5985 28.6008 14.3182 28.9354 15L29.9392 14.0909L29.6882 12.3106C30.2319 11.9318 30.7338 11.5152 31.2357 11.0985C33.2015 9.24242 34.4981 7.08333 35 5Z"
                fill="white"
            />
        </svg>
    );
};

export default CodexLogo;
