import { useEffect, useState } from 'react';

const useResizeObserver = (id: string, widthScreen: number = 768): number => {
    const [isVisible, setIsVisible] = useState(0);

    useEffect(() => {
        const container = document.getElementById(id);
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.contentBoxSize) {
                    const contentBoxSize = Array.isArray(entry.contentBoxSize)
                        ? entry.contentBoxSize[0]
                        : entry.contentBoxSize;
                    if (contentBoxSize.inlineSize) {
                        setIsVisible(contentBoxSize.inlineSize);
                    } else {
                        setIsVisible(entry.contentRect.width);
                    }
                }
            }
        });

        if (container) {
            resizeObserver.observe(container);
            return () => resizeObserver.unobserve(container);
        }
    }, [id, widthScreen]);

    return isVisible;
};

export default useResizeObserver;
