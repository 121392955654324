import { IconProps } from './icon.types';

const Browser: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_982_1335)">
                <path
                    d="M39.5318 4.70312V8.46841C39.5318 9.50856 38.6893 10.3511 37.6491 10.3511C36.6089 10.3511 35.7664 9.50856 35.7664 8.46841V4.70312C33.5007 5.50422 31.8985 7.70884 32.0058 10.2729C32.0971 12.4352 33.4254 14.2952 35.2958 15.1603V19.4678C36.8646 19.8632 38.4334 19.8632 40.0023 19.4678V15.1631C41.9461 14.2698 43.2971 12.3071 43.297 10.0282C43.2972 7.56944 41.7251 5.47872 39.5318 4.70312Z"
                    fill="#FFBEBC"
                />
                <path
                    d="M30.3321 16.272H11.1253C9.22999 16.272 7.69971 17.8073 7.69971 19.6966C7.69971 21.585 9.23646 23.1213 11.1253 23.1213H33.1097C35.0015 23.1213 36.5336 21.5912 36.5344 19.6981C34.0593 19.4057 31.8579 18.155 30.3321 16.272Z"
                    fill="#FFBEBC"
                />
                <path
                    d="M5.64401 9.40604C6.16048 9.40604 6.5817 8.98539 6.5817 8.46845C6.5817 7.95207 6.16095 7.53076 5.64411 7.53076C5.12764 7.53076 4.70642 7.95142 4.70642 8.46836C4.70633 8.98492 5.12698 9.40604 5.64401 9.40604Z"
                    fill="#212529"
                />
                <path
                    d="M10.3508 9.40595C10.8673 9.40595 11.2884 8.98529 11.2884 8.46836C11.2884 7.95198 10.8676 7.53076 10.3508 7.53076C9.83443 7.53076 9.41321 7.95142 9.41321 8.46836C9.4133 8.98482 9.83386 9.40595 10.3508 9.40595Z"
                    fill="#212529"
                />
                <path
                    d="M15.0572 9.40595C15.5737 9.40595 15.9948 8.98539 15.9948 8.46836C15.9948 7.95198 15.5741 7.53076 15.0572 7.53076C14.5408 7.53076 14.1196 7.95142 14.1196 8.46836C14.1196 8.98482 14.5403 9.40595 15.0572 9.40595Z"
                    fill="#212529"
                />
                <path
                    d="M48 10.3509C48 4.64344 43.3566 0 37.6491 0C34.4411 0 31.5693 1.467 29.6692 3.76528H3.76153C1.68741 3.76528 0 5.45269 0 7.52672V44.2385C0 46.3126 1.68741 48 3.76153 48H40.4733C42.5473 48 44.2347 46.3126 44.2347 44.2385V18.3338C46.6291 16.3617 48 13.4611 48 10.3509ZM37.6491 1.875C42.3227 1.875 46.125 5.67731 46.125 10.3509C46.125 15.7929 41.0763 19.8051 35.7951 18.6233C31.9581 17.7687 29.1731 14.2897 29.1731 10.3509C29.1731 5.67731 32.9753 1.875 37.6491 1.875ZM3.76153 5.64028H28.4334C28.1261 6.23906 27.8755 6.87159 27.6887 7.53056H19.764C19.2462 7.53056 18.8265 7.95037 18.8265 8.46806C18.8265 8.98575 19.2462 9.40556 19.764 9.40556H27.3417C27.2815 10.0672 27.2853 10.6726 27.3428 11.2959H1.875V7.52672C1.875 6.48647 2.72128 5.64028 3.76153 5.64028ZM40.4733 46.125H3.76153C2.72128 46.125 1.875 45.2787 1.875 44.2385V13.1709H27.6937C29.4847 19.4852 36.5959 22.5274 42.3597 19.5674V44.2385C42.3597 45.2787 41.5134 46.125 40.4733 46.125Z"
                    fill="#212529"
                />
                <path
                    d="M38.5906 26.2227H5.64417C5.12638 26.2227 4.70667 26.6424 4.70667 27.1602C4.70667 27.6778 5.12638 28.0977 5.64417 28.0977H38.5906C39.1083 28.0977 39.5281 27.6778 39.5281 27.1602C39.5281 26.6424 39.1083 26.2227 38.5906 26.2227Z"
                    fill="#212529"
                />
                <path
                    d="M38.5906 31.1982H5.64417C5.12638 31.1982 4.70667 31.618 4.70667 32.1357C4.70667 32.6534 5.12638 33.0732 5.64417 33.0732H38.5906C39.1083 33.0732 39.5281 32.6534 39.5281 32.1357C39.5281 31.6181 39.1083 31.1982 38.5906 31.1982Z"
                    fill="#212529"
                />
                <path
                    d="M38.5906 36.1738H5.64417C5.12638 36.1738 4.70667 36.5936 4.70667 37.1113C4.70667 37.6291 5.12638 38.0488 5.64417 38.0488H38.5906C39.1083 38.0488 39.5281 37.6291 39.5281 37.1113C39.5281 36.5936 39.1083 36.1738 38.5906 36.1738Z"
                    fill="#212529"
                />
                <path
                    d="M38.5906 41.1494H29.1774C28.6596 41.1494 28.2399 41.5692 28.2399 42.0869C28.2399 42.6046 28.6596 43.0244 29.1774 43.0244H38.5906C39.1083 43.0244 39.5281 42.6046 39.5281 42.0869C39.5281 41.5691 39.1083 41.1494 38.5906 41.1494Z"
                    fill="#212529"
                />
            </g>
            <defs>
                <clipPath id="clip0_982_1335">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Browser;
