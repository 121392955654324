import styled from 'styled-components';
import { Icon, Typography } from '../../../../../shared';
import convertSize from '../../../../../shared/helpers/convertSize';

export const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: ${convertSize(478)};

    @media (max-width: 1440px) {
        width: 478px;
    }

    @media (max-width: 767px) {
        width: 426px;
    }

    @media (max-width: 479px) {
        width: 335px;
    }
`;

export const Title = styled(Typography)`
    text-align: center;
`;

export const List = styled.ul`
    margin: ${convertSize(12)} 0;
    padding: 0;

    @media (max-width: 1440px) {
        margin: 12px 0;
    }
`;

export const ListTitleContainer = styled.div`
    display: flex;
    align-items: start;
`;

export const ListTitle = styled(Typography)`
    margin-left: ${convertSize(15)};
    margin-right: ${convertSize(13)};
    letter-spacing: 0;

    @media (max-width: 1440px) {
        margin-left: 15px;
        margin-right: 13px;
    }
`;

export const Item = styled.li`
    margin: ${convertSize(12)} 0 ${convertSize(12)} ${convertSize(65)};
    list-style: none;

    @media (max-width: 1440px) {
        margin: 12px 0 12px 65px;
    }
`;

export const ItemText = styled(Typography)`
    letter-spacing: 0;
`;

export const StyledIcon = styled(Icon)`
    width: ${convertSize(24)};
    height: ${convertSize(24)};

    @media (max-width: 1440px) {
        width: 24px;
        height: 24px;
    }
`;

export const ToggleIcon = styled(StyledIcon)<{ isToggle: boolean }>`
    transition: 0.3s;
    transform: ${({ isToggle }) => `rotate(${isToggle ? 180 : 0}deg)`};
    cursor: pointer;
`;
