import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000',
});

type MessageInfoType = {
    name: string;
    email: string;
    description: string;
};

const emailService = {
    consultation(message: MessageInfoType) {
        return axiosInstance
            .post('/consultation', { ...message })
            .then((response) => response.data)
            .catch((error) => console.error(error));
    },
    bespeak(email: string) {
        return axiosInstance
            .post('/bespeak', { email })
            .then((response) => response.data)
            .catch((error) => console.error(error));
    },
};

export default emailService;
