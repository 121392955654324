import styled from 'styled-components';
import convertSize from '../../helpers/convertSize';
import { Button, Icon, Typography } from '../../ui-kit';

export const Header = styled.header`
    position: sticky;
    position: -webkit-sticky;
    z-index: 10;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: ${convertSize(80)};
    background-color: #171a1d;

    @media (max-width: 1440px) {
        height: 80px;
    }

    @media (max-width: 1240px) {
        padding: 0 20px;
        box-sizing: border-box;
    }
`;

export const ContactUsButton = styled(Button)`
    width: ${convertSize(150)};
    height: ${convertSize(48)};
    margin-right: ${convertSize(15)};
    background-color: #a4fcdc;

    &:hover {
        background-color: #a4fcdc;
    }

    @media (max-width: 1440px) {
        width: 150px;
        height: 48px;
        margin-right: 15px;
    }

    @media (max-width: 479px) {
        width: 48px;
    }
`;

export const ButtonText = styled(Typography)`
    line-height: ${convertSize(21)};
    letter-spacing: 0;

    @media (max-width: 1440px) {
        line-height: 21px;
    }

    @media (max-width: 479px) {
        display: none;
    }
`;

export const ButtonIcon = styled(Icon)`
    display: none;

    @media (max-width: 479px) {
        display: block;
    }
`;

export const LogoIcon = styled(Icon)`
    width: ${convertSize(136)};
    height: ${convertSize(44)};
    cursor: pointer;

    @media (max-width: 1440px) {
        width: 136px;
        height: 44px;
    }
`;

export const ButtonBlock = styled.div`
    display: flex;
`;

export const SiteLink = styled.a`
    display: block;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: transparent;
    border-radius: ${convertSize(8)};
    padding: 0 ${convertSize(15)};
    background-color: #343840;
    min-height: ${convertSize(40)};
    width: ${convertSize(160)};

    @media (max-width: 1440px) {
        border-radius: 8px;
        padding: 0 15px;
        min-height: 40px;
        width: 160px;
    }

    @media (max-width: 769px) {
        width: auto;
    }
`;

export const SiteTextBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

export const IconBlock = styled.div`
    display: flex;
    align-self: center;
    justify-content: center;
`;

export const GlobalIcon = styled(Icon)``;

export const SiteText = styled(Typography)`
    letter-spacing: 0;
    line-height: ${convertSize(21)};
    color: #828282;
    font-size: ${convertSize(12)};

    @media (max-width: 1440px) {
        line-height: 21px;
        font-size: 12px;
    }

    @media (max-width: 767px) {
        display: none;
    }
`;

export const Site = styled(SiteText)`
    color: #ffffff;
    font-size: 14px;
`;
