import { StyledComponent } from 'styled-components';
import { ButtonHTMLAttributes } from 'react';

import * as Styled from './button.styles';

export type ButtonVariant = 'contained' | 'outlined';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: ButtonVariant;
    type?: 'button' | 'submit' | 'reset';
}

const variantStyledMap: Record<ButtonVariant, StyledComponent<'button', HTMLButtonElement>> = {
    contained: Styled.ButtonContainer,
    outlined: Styled.OutlinedContainer,
};

const Button: React.FC<ButtonProps> = ({ variant = 'contained', children, ...props }) => {
    const Container = variantStyledMap[variant];

    return <Container {...props}>{children}</Container>;
};

export default Button;
