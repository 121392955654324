import styled from 'styled-components';
import convertSize from '../../helpers/convertSize';

export const Container = styled.div``;

export const Element = styled.input<{
    icon?: string;
    inputSize?: 'small' | 'default';
}>`
    height: ${convertSize(64)};
    width: ${convertSize(418)};
    outline: none;
    margin: 0;
    font-family: 'SF Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${convertSize(18)};
    line-height: ${convertSize(24)};
    border-radius: ${convertSize(8)};
    background-color: #ffffff;
    color: #212529;
    border: none;
    padding-left: ${convertSize(12)};
    box-sizing: border-box;

    &::placeholder {
        color: rgba(163, 163, 163, 0.8);
    }

    @media (max-width: 1440px) {
        height: 64px;
        width: 418px;
        font-size: 18px;
        line-height: 24px;
        border-radius: 8px;
        padding-left: 12px;
    }
`;
