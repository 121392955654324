import styled from 'styled-components';
import convertSize from '../../helpers/convertSize';
import { Icon, Typography } from '../../ui-kit';

export const Footer = styled.footer`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: ${convertSize(80)};
    background-color: #171a1d;

    @media (max-width: 1440px) {
        height: 80px;
    }

    @media (max-width: 1240px) {
        padding: 0 20px;
        box-sizing: border-box;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Logo = styled(Icon)`
    margin-right: ${convertSize(3)};
    width: ${convertSize(24)};
    height: ${convertSize(24)};

    @media (max-width: 1440px) {
        margin-right: 3px;
        width: 24px;
        height: 24px;
    }
`;

export const Label = styled(Typography)`
    color: #ffffff;
    font-size: ${convertSize(12)};
    line-height: ${convertSize(24)};
    letter-spacing: 0;

    @media (max-width: 1440px) {
        font-size: 12px;
        line-height: 24px;
    }
`;

export const SocialMediaIcon = styled(Icon)`
    width: ${convertSize(32)};
    height: ${convertSize(32)};
    cursor: pointer;

    @media (max-width: 1440px) {
        width: 32px;
        height: 32px;
    }
`;
