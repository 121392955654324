import styled from 'styled-components';
import convertSize from '../../../shared/helpers/convertSize';

export const CompaniesContainer = styled.section`
    background-color: #f5f5f5;
    padding: ${convertSize(60)} 0 ${convertSize(50)} 0;
    box-sizing: border-box;

    @media (max-width: 1440px) {
        padding: 60px 0 50px 0;
    }

    @media (max-width: 1240px) {
        padding: 50px 20px 40px 20px;
    }

    @media (max-width: 767px) {
        padding: 50px 20px 0px 20px;
    }
`;

export const CompaniesContent = styled.div`
    display: grid;
    grid-template-columns: repeat(6, auto);
    max-width: ${convertSize(1200)};
    width: auto;
    height: 100%;
    margin: 0 auto;

    @media (max-width: 1440px) {
        max-width: 1200px;
    }

    @media (max-width: 1024px) {
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(2 1fr);
    }

    @media (max-width: 767px) {
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(3 1fr);
    }

    @media (max-width: 479px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const CompanyIconBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${convertSize(10)} 0;
    box-sizing: border-box;

    @media (max-width: 1440px) {
        padding: 10px 0;
    }
`;

export const CompanyIcon = styled.img<{ width: number; height: number }>`
    width: ${({ width }) => convertSize(width)};
    height: ${({ height }) => convertSize(height)};

    @media (max-width: 1440px) {
        width: ${({ width }) => `${width}px`};
        height: ${({ height }) => `${height}px`};
    }
`;
