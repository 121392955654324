import { IconProps } from './icon.types';

const Logo: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="136" height="44" viewBox="0 0 136 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_754_635)">
                <path
                    d="M33.2726 14.9516C33.1139 14.6846 32.7436 14.6312 32.4791 14.7914L28.935 17.0875C29.6756 18.2622 30.0987 19.5972 30.0987 21.0923C30.0987 25.1506 26.8191 28.4613 22.7989 28.4613C18.7786 28.4613 15.499 25.1506 15.499 21.0923C15.499 17.0341 18.7786 13.7234 22.7989 13.7234C23.751 13.7234 24.6503 13.8836 25.4966 14.2574L22.7989 21.1457L26.7662 14.9516L29.0408 11.4273C29.1995 11.1603 29.1466 10.7865 28.8821 10.6263C28.3531 10.3059 27.8241 9.98553 27.2952 9.71854C21.4764 6.78165 14.2823 7.84961 9.57443 12.6554C3.54409 18.5826 3.54409 28.4079 9.52153 34.4418C15.499 40.4758 25.2322 40.4758 31.2625 34.4418C36.4994 29.1554 37.187 20.9855 33.2726 14.9516Z"
                    fill="white"
                />
                <path
                    d="M43.6935 0C40.7841 0.640777 37.7689 2.24272 35.2298 4.80582C34.5951 5.4466 34.0132 6.08738 33.5371 6.78155L31.0509 6.46116L29.7814 7.74272C30.9451 8.2767 32.1089 9.07767 33.1668 10.0922L32.6378 10.6262L33.2197 11.2136C34.8067 9.61165 35.6001 8.81068 36.9755 7.42233L34.1719 11.2136C34.9653 12.1214 35.6001 13.1359 36.0233 14.0971L37.2928 12.8155L36.9755 10.3058C37.6631 9.77184 38.2979 9.18447 38.9327 8.59709C41.4189 5.98058 43.0587 2.93689 43.6935 0Z"
                    fill="white"
                />
                <path
                    d="M58.7694 16.3398H53.0035C42.5298 16.3398 42.5298 30.4369 53.0035 30.4369H58.7694C59.457 30.4369 59.986 29.903 59.986 29.2088V29.1554C59.986 28.4612 59.457 27.9272 58.7694 27.9272H53.0035C46.55 27.6602 46.55 19.1699 53.0035 18.9563H58.7694C59.457 18.9563 59.986 18.4224 59.986 17.7282V17.6214C59.986 16.9272 59.457 16.3398 58.7694 16.3398Z"
                    fill="white"
                />
                <path
                    d="M72.946 27.9272C72.8931 27.9272 72.8931 27.9272 72.946 27.9272H72.417H71.888C65.4345 27.6602 65.4345 19.1699 71.888 18.9563H72.417H72.946C79.3466 19.1699 79.3466 27.6602 72.946 27.9272ZM72.946 16.3398H72.417H71.888C61.4143 16.3398 61.4143 30.4369 71.888 30.4369H72.417H72.946C83.4197 30.4369 83.4197 16.3398 72.946 16.3398Z"
                    fill="white"
                />
                <path
                    d="M135.683 28.4079L131.239 23.3885L135.63 18.4758C135.947 18.102 136.053 17.5681 135.841 17.1409C135.63 16.7137 135.207 16.3933 134.73 16.3933C134.149 16.3933 133.62 16.6603 133.249 17.0341L129.441 21.3593L125.632 17.0341C125.262 16.6069 124.733 16.3933 124.151 16.3933C123.675 16.3933 123.252 16.6603 123.04 17.1409C122.828 17.5681 122.934 18.102 123.252 18.4758L127.642 23.3885L123.199 28.4079C122.881 28.7817 122.776 29.3156 122.987 29.7428C123.199 30.17 123.622 30.4904 124.098 30.4904C124.627 30.4904 125.103 30.2768 125.42 29.903L129.388 25.4176L133.355 29.903C133.672 30.2768 134.201 30.4904 134.678 30.4904C135.154 30.4904 135.577 30.2234 135.788 29.7428C136.053 29.2622 136 28.7817 135.683 28.4079Z"
                    fill="white"
                />
                <path
                    d="M91.7775 27.9272C91.7246 27.9272 91.7246 27.9272 91.7775 27.9272H87.6515V18.903H91.7775C98.2311 19.1699 98.2311 27.6602 91.7775 27.9272ZM91.7775 16.3398H86.0117C85.324 16.3398 84.795 16.8738 84.795 17.568V29.2088C84.795 29.903 85.324 30.4369 86.0117 30.4369H91.7775C102.251 30.4369 102.251 16.3398 91.7775 16.3398Z"
                    fill="white"
                />
                <path
                    d="M116.851 16.3398H111.085C100.611 16.3398 100.611 30.4369 111.085 30.4369H116.851C117.539 30.4369 118.068 29.903 118.068 29.2088V29.1554C118.068 28.4612 117.539 27.9272 116.851 27.9272H111.085C104.632 27.6602 104.632 19.1699 111.085 18.9563H116.851C117.539 18.9563 118.068 18.4224 118.068 17.7282V17.6214C118.068 16.9272 117.539 16.3398 116.851 16.3398Z"
                    fill="white"
                />
                <path
                    d="M110.662 22.1067C109.974 22.1067 109.392 22.6941 109.392 23.3882C109.392 24.0824 109.974 24.6698 110.662 24.6698H116.428C117.116 24.6698 117.644 24.1358 117.644 23.4416V23.3882C117.644 22.6941 117.116 22.1601 116.428 22.1601H110.662V22.1067Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_754_635">
                    <rect width="136" height="44" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Logo;
