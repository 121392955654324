import styled from 'styled-components';
import convertSize from '../../helpers/convertSize';

interface ToastProps {
    borderColor: string;
    backgroundColor: string;
}

export const Toast = styled.div<ToastProps>`
    border: ${convertSize(2)} solid ${({ borderColor }) => borderColor};
    background-color: ${({ backgroundColor }) => backgroundColor};
    padding: ${convertSize(16)} ${convertSize(22)};
    border-radius: ${convertSize(6)};
    max-width: ${convertSize(480)};
    box-shadow: 0px 0px ${convertSize(5)} rgba(0, 0, 0, 0.2);
    margin-top: ${convertSize(16)};
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 1440px) {
        border: 2px solid ${({ borderColor }) => borderColor};
        padding: 16px 22px;
        border-radius: 6px;
        max-width: 480px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        margin-top: 16px;
    }
`;
export const ButtonClose = styled.div`
    position: absolute;
    cursor: pointer;
    width: ${convertSize(24)};
    height: ${convertSize(24)};
    right: ${convertSize(5)};
    top: ${convertSize(5)};

    @media (max-width: 1440px) {
        width: 24px;
        height: 24px;
        right: 5px;
        top: 5px;
    }
`;
