import styled from 'styled-components';
import background from '../../../assets/background/background.png';
import image from '../../../assets/background/image.png';
import { Button, Typography } from '../../../shared';
import convertSize from '../../../shared/helpers/convertSize';

export const IntroductionContainer = styled.section`
    background: url(${background});
    background-size: cover;
    height: calc(100vh - ${convertSize(80)});

    @media (max-width: 1440px) {
        height: calc(100vh - 80px);
    }

    @media (max-width: 1240px) {
        padding: 0 20px;
        box-sizing: border-box;
    }
`;

export const IntroductionBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: ${convertSize(1200)};
    width: 100%;
    height: 100%;
    margin: 0 auto;

    @media (max-width: 1440px) {
        max-width: 1200px;
    }
`;

export const IntroductionContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 767px) {
        align-items: center;
    }
`;

export const TitleBlock = styled.div`
    width: ${convertSize(600)};
    margin-bottom: ${convertSize(32)};

    @media (max-width: 1440px) {
        width: 600px;
        margin-bottom: 32px;
    }

    @media (max-width: 767px) {
        width: auto;
    }
`;

export const DescriptionBlock = styled.div`
    width: ${convertSize(590)};

    @media (max-width: 1440px) {
        width: 590px;
    }

    @media (max-width: 767px) {
        width: auto;
    }
`;

export const Title = styled(Typography)`
    color: #212529;
`;

export const Description = styled(Typography)`
    color: #212529;
    font-weight: 400;
    letter-spacing: 0;
`;

export const ContactUsButton = styled(Button)`
    margin-top: ${convertSize(48)};
    width: ${convertSize(200)};
    height: ${convertSize(64)};
    font-family: 'SF Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: ${convertSize(18)};
    line-height: ${convertSize(24)};
    color: #ffffff;
    filter: drop-shadow(0px 24px 48px rgba(1, 71, 81, 0.24));

    @media (max-width: 1440px) {
        margin-top: 48px;
        width: 200px;
        height: 64px;
        font-size: 18px;
        line-height: 24px;
    }
`;

export const ImageBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (max-width: 1024px) {
        display: none;
    }
`;

export const Image = styled.div`
    background: url(${image});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
`;
