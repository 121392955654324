import { IconProps } from './icon.types';

const OnlineBooking: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_982_1325)">
                <path
                    d="M35.7647 25.8826C31.094 25.8826 27.2941 22.0827 27.2941 17.412C27.2941 12.7413 31.094 8.94141 35.7647 8.94141C40.4353 8.94141 44.2352 12.7413 44.2352 17.412C44.2352 22.0827 40.4353 25.8826 35.7647 25.8826Z"
                    fill="#A4FCDC"
                />
                <path d="M25.4117 25.8825H3.76465V16.4707H25.4117V25.8825Z" fill="#A4FCDC" />
                <path
                    d="M33.9384 20.4304C34.3062 20.7979 34.9018 20.7978 35.2695 20.4304L39.5048 16.1951C39.8724 15.8275 39.8724 15.2316 39.5048 14.864C39.1372 14.4965 38.5414 14.4965 38.1737 14.864L34.6039 18.4338L33.3557 17.1856C32.988 16.8181 32.3921 16.8181 32.0245 17.1856C31.657 17.5531 31.657 18.149 32.0245 18.5167L33.9384 20.4304Z"
                    fill="#212529"
                />
                <path
                    d="M44.2353 4.23535H3.76471C1.68885 4.23535 0 5.9242 0 8.00006V32.4706C0 34.5465 1.68885 36.2354 3.76471 36.2354H19.3478L17.2301 41.8824H15.0588C14.539 41.8824 14.1176 42.3039 14.1176 42.8236C14.1176 43.3433 14.539 43.7648 15.0588 43.7648H32.9412C33.4609 43.7648 33.8824 43.3433 33.8824 42.8236C33.8824 42.3039 33.4609 41.8824 32.9412 41.8824H30.7699L28.6522 36.2354H44.2353C46.3112 36.2354 48 34.5465 48 32.4706V8.00006C48 5.9242 46.3112 4.23535 44.2353 4.23535ZM3.76471 6.1177H44.2353C45.2732 6.1177 46.1176 6.96213 46.1176 8.00006V28.7059H1.88235V8.00006C1.88235 6.96213 2.72678 6.1177 3.76471 6.1177ZM28.7595 41.8824H19.2405L21.3581 36.2354H26.642L28.7595 41.8824ZM44.2353 34.353C42.3019 34.353 5.23398 34.353 3.76471 34.353C2.72678 34.353 1.88235 33.5086 1.88235 32.4706V30.5883H46.1176V32.4706C46.1176 33.5086 45.2732 34.353 44.2353 34.353Z"
                    fill="#212529"
                />
                <path
                    d="M4.70582 14.5884H24.4705C24.9902 14.5884 25.4117 14.167 25.4117 13.6472C25.4117 13.1274 24.9902 12.7061 24.4705 12.7061H4.70582C4.18611 12.7061 3.76465 13.1274 3.76465 13.6472C3.76465 14.167 4.18611 14.5884 4.70582 14.5884Z"
                    fill="#212529"
                />
                <path
                    d="M4.70582 10.8238H18.8235C19.3433 10.8238 19.7646 10.4023 19.7646 9.88258C19.7646 9.36287 19.3433 8.94141 18.8235 8.94141H4.70582C4.18611 8.94141 3.76465 9.36287 3.76465 9.88258C3.76465 10.4023 4.18611 10.8238 4.70582 10.8238Z"
                    fill="#212529"
                />
            </g>
            <defs>
                <clipPath id="clip0_982_1325">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default OnlineBooking;
