import { useContext } from 'react';
import { NavigateContext } from '../../../shared';
import * as Styled from './introduction.styles';

const Introduction = () => {
    const { scrollToContactUsForm, introductionRef } = useContext(NavigateContext);

    return (
        <Styled.IntroductionContainer ref={introductionRef}>
            <Styled.IntroductionBlock>
                <Styled.IntroductionContent>
                    <Styled.TitleBlock>
                        <Styled.Title type="h1">Custom bot development for e-Commerce</Styled.Title>
                    </Styled.TitleBlock>
                    <Styled.DescriptionBlock>
                        <Styled.Description type="h6">
                        We develop custom bots for e-Commerce to gather specific information from your sales channels.
                        </Styled.Description>
                    </Styled.DescriptionBlock>
                    <Styled.ContactUsButton onClick={scrollToContactUsForm}>Contact Us</Styled.ContactUsButton>
                </Styled.IntroductionContent>
                <Styled.ImageBlock>
                    <Styled.Image />
                </Styled.ImageBlock>
            </Styled.IntroductionBlock>
        </Styled.IntroductionContainer>
    );
};

export default Introduction;
