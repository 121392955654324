import { IconProps } from './icon.types';

const Like: React.FC<IconProps> = (props) => {
    return (
        <svg width={80} height={80} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_1127_204)">
                <path
                    d="M80 42.566a5.92 5.92 0 00-5.913-5.913h-14.22v-10.14c0-4.251-3.457-7.71-7.708-7.71h-2.555c-.863 0-1.563.7-1.563 1.563v9.97c0 3.324-2.378 7.243-5.575 8.247v-1.038c0-.863-.7-1.563-1.563-1.563h-8.088a1.562 1.562 0 100 3.126h6.526v35.059h-6.526a1.563 1.563 0 000 3.124h8.088c.863 0 1.563-.699 1.563-1.562v-1.146h26.737a5.92 5.92 0 005.913-5.913 5.88 5.88 0 00-1.088-3.416 5.92 5.92 0 003.263-5.285 5.88 5.88 0 00-1.06-3.376 5.92 5.92 0 003.344-5.325 5.895 5.895 0 00-1.71-4.156A5.904 5.904 0 0080 42.566zm-5.913 2.788H63.304a1.563 1.563 0 000 3.126h10.358a2.791 2.791 0 012.788 2.788 2.791 2.791 0 01-2.788 2.788l-10.358-.002a1.563 1.563 0 000 3.125h8.035l.04.002a2.791 2.791 0 012.787 2.788 2.791 2.791 0 01-2.788 2.788l-8.074-.001a1.563 1.563 0 000 3.125h5.899a2.792 2.792 0 012.788 2.789 2.792 2.792 0 01-2.788 2.788H42.466V41.807c5.073-.963 8.7-6.486 8.7-11.471v-8.407h.993a4.59 4.59 0 014.584 4.584v11.703c0 .863.7 1.562 1.562 1.562h15.782a2.791 2.791 0 012.788 2.788 2.792 2.792 0 01-2.788 2.788zM52.19 13.056c.863 0 1.563-.7 1.563-1.562V1.563a1.562 1.562 0 10-3.125 0v9.93c0 .864.7 1.563 1.562 1.563zM41.66 17.161a1.563 1.563 0 002.21-2.21L36.848 7.93a1.563 1.563 0 00-2.21 2.21l7.022 7.022z"
                    fill="#171A1D"
                />
                <path
                    d="M40.89 25.947c0-.863-.7-1.562-1.563-1.562h-9.931a1.563 1.563 0 000 3.125h9.931c.863 0 1.563-.7 1.563-1.563zM65.082 24.356c0 .863.699 1.563 1.562 1.563h9.931a1.563 1.563 0 000-3.125h-9.931c-.863 0-1.563.7-1.563 1.562zM62.08 16.494c.4 0 .8-.153 1.106-.458l7.022-7.022a1.562 1.562 0 10-2.21-2.21l-7.022 7.023a1.563 1.563 0 001.105 2.667z"
                    fill="#171A1D"
                />
                <path d="M0 33.273h32.815V80H0V33.273z" fill="#A4FCDC" />
                <path
                    d="M21.845 64.238a4.72 4.72 0 00-4.716 4.716 4.72 4.72 0 004.715 4.715 4.72 4.72 0 004.716-4.715 4.72 4.72 0 00-4.715-4.716zm0 6.306c-.877 0-1.59-.713-1.59-1.59 0-.877.713-1.59 1.59-1.59.876 0 1.59.713 1.59 1.59 0 .877-.714 1.59-1.59 1.59z"
                    fill="#171A1D"
                />
            </g>
            <defs>
                <clipPath id="clip0_1127_204">
                    <path fill="#fff" d="M0 0H80V80H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Like;
