import { LegacyRef, ReactElement, SVGProps } from 'react';

export enum E_ICON_TYPE {
    responsive = 'responsive',
    chatBot = 'chatBot',
    calendar = 'calendar',
    customerSupport = 'customerSupport',
    browser = 'browser',
    onlineBooking = 'onlineBooking',
    apartment = 'apartment',
    arrow = 'arrow',
    arrowDown = 'arrowDown',
    brain = 'brain',
    chat = 'chat',
    discussion = 'discussion',
    emailLogo = 'emailLogo',
    email = 'email',
    codexLogo = 'codexLogo',
    heartRate = 'heartRate',
    hierarchicalStructure = 'hierarchicalStructure',
    linkedInLogo = 'linkedInLogo',
    liveStreaming = 'liveStreaming',
    logo = 'logo',
    scissors = 'scissors',
    outlineDone = 'outlineDone',
    arrowToggle = 'arrowToggle',
    yahooMail = 'yahooMail',
    document = 'document',
    moneyBag = 'moneyBag',
    pieChart = 'pieChart',
    profits = 'profits',
    service = 'service',
    close = 'close',
    like = 'like',
    global = 'global',
}

export type T_MAP_TYPE = {
    [key in E_ICON_TYPE]: (props: IconProps) => ReactElement;
};

export interface IconProps extends SVGProps<SVGSVGElement> {
    type: E_ICON_TYPE;
    passedRef?: LegacyRef<SVGSVGElement>;
}
