import styled from 'styled-components';
import { Icon, Typography } from '../../../shared';
import convertSize from '../../../shared/helpers/convertSize';

export const HowWorkContainer = styled.section`
    padding: ${convertSize(150)} 0;
    background-color: #f5f5f5;

    @media (max-width: 1440px) {
        padding: 150px 0;
    }

    @media (max-width: 1240px) {
        padding: 150px 20px;
        box-sizing: border-box;
    }
`;

export const HowWorkContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: ${convertSize(1200)};
    width: auto;
    margin: 0 auto;

    @media (max-width: 1440px) {
        max-width: 1200px;
    }
`;

export const Cards = styled.div`
    display: flex;
    margin-top: ${convertSize(32)};
    flex-wrap: wrap;

    @media (max-width: 1440px) {
        margin-top: 32px;
    }

    @media (max-width: 479px) {
        margin-top: 24px;
        flex-wrap: nowrap;
        flex-direction: column;
    }
`;

export const Card = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media (max-width: 479px) {
        margin-top: 84px;

        &:first-child {
            margin-top: 0;
        }
    }
`;

export const IconBLock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: ${convertSize(180)};
    height: ${convertSize(180)};

    @media (max-width: 1440px) {
        width: 180px;
        height: 180px;
    }

    @media (max-width: 479px) {
        height: 120px;
    }
`;

export const Description = styled(Typography)`
    text-align: center;
    margin-top: ${convertSize(24)};
    font-weight: 500;
    letter-spacing: 0;
    width: ${convertSize(176)};

    @media (max-width: 1440px) {
        margin-top: 24px;
        width: 176px;
    }

    @media (max-width: 479px) {
        width: 335px;
    }
`;

export const DesktopArrow = styled(Icon)`
    position: absolute;
    width: ${convertSize(42)};
    height: ${convertSize(24)};
    right: -${convertSize(20)};
    display: block;

    @media (max-width: 1440px) {
        right: -20px;
        width: 42px;
        height: 24px;
    }

    @media (max-width: 479px) {
        display: none;
    }
`;

export const MobileArrow = styled(Icon)`
    position: absolute;
    bottom: -65px;
    display: none;
    transform: rotate(90deg);

    @media (max-width: 479px) {
        display: block;
    }
`;

export const StyledIcon = styled(Icon)`
    width: ${convertSize(80)};
    height: ${convertSize(80)};

    @media (max-width: 1440px) {
        width: 80px;
        height: 80px;
    }
`;
