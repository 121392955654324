import React, { createContext, useRef } from 'react';
import useResizeObserver from '../../helpers/useResizeObserver';

type NavigateContextType = {
    scrollToContactUsForm: () => void;
    scrollToBespeakForm: () => void;
    scrollToIntroduction: () => void;
    contactUsRef: React.RefObject<HTMLDivElement>;
    bespeakRef: React.RefObject<HTMLDivElement>;
    introductionRef: React.RefObject<HTMLDivElement>;
};

export const NavigateContext = createContext<NavigateContextType>({
    scrollToBespeakForm: () => {},
    scrollToContactUsForm: () => {},
    scrollToIntroduction: () => {},
    contactUsRef: React.createRef(),
    bespeakRef: React.createRef(),
    introductionRef: React.createRef(),
});

const NavigateContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const contactUsRef = useRef<HTMLDivElement>(null);
    const bespeakRef = useRef<HTMLDivElement>(null);
    const introductionRef = useRef<HTMLDivElement>(null);
    const screenWidth = useResizeObserver('feature-container');

    const onScroll = (elementRef: React.RefObject<HTMLDivElement>, rotation = 80) => {
        if (elementRef.current) {
            console.log(elementRef.current);
            const ratio = screenWidth > 1440 ? (screenWidth / 1800) * 100 : rotation;
            window.scrollTo({ behavior: 'smooth', top: elementRef.current?.offsetTop - ratio });
        }
    };

    const scrollToContactUsForm = () => {
        onScroll(contactUsRef);
    };

    const scrollToBespeakForm = () => {
        onScroll(bespeakRef);
    };

    const scrollToIntroduction = () => {
        onScroll(introductionRef);
    };

    const value = {
        contactUsRef,
        bespeakRef,
        introductionRef,
        scrollToContactUsForm,
        scrollToBespeakForm,
        scrollToIntroduction,
    };

    return <NavigateContext.Provider value={value}>{children}</NavigateContext.Provider>;
};

export default NavigateContextProvider;
