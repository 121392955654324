import { forwardRef } from 'react';

import { ICON_TYPES, Typography } from '../../../shared';
import * as Styled from './benefit.styles';

const images = [
    { src: ICON_TYPES.profits, name: 'Sales increase' },
    { src: ICON_TYPES.moneyBag, name: 'Cost-effective' },
    { src: ICON_TYPES.pieChart, name: 'Data insight' },
    { src: ICON_TYPES.document, name: 'GDPR compliant' },
];

const Benefit = forwardRef<any, any>((props, ref) => {
    return (
        <Styled.BenefitContainer ref={ref} {...props}>
            <Styled.BenefitWrapper>
                <Styled.Title type="h3">The benefits you will get</Styled.Title>
                <Styled.BenefitBlock>
                    {images.map(({ src, name }) => (
                        <Styled.BenefitContent key={src}>
                            <Styled.IconContainer>
                                <Styled.StyledIcon type={src} />
                            </Styled.IconContainer>
                            <Typography type="h6">{name}</Typography>
                        </Styled.BenefitContent>
                    ))}
                </Styled.BenefitBlock>
            </Styled.BenefitWrapper>
        </Styled.BenefitContainer>
    );
});

export default Benefit;
