import styled from 'styled-components';
import { Button, Input, Typography } from '../../../shared';
import background2 from '../../../assets/background/background2.png';
import convertSize from '../../../shared/helpers/convertSize';

export const BespeakContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(${background2});
    background-size: cover;
    background-position: center;
    padding: ${convertSize(150)};
    box-sizing: border-box;

    @media (max-width: 1440px) {
        padding: 150px;
    }

    @media (max-width: 767px) {
        padding: 150px 20px;
    }
`;

export const Title = styled(Typography)`
    text-align: center;
    color: #ffffff;
`;

export const Description = styled(Typography)`
    margin-top: ${convertSize(24)};
    font-weight: 500;
    text-align: center;
    color: #ffffff;

    @media (max-width: 1440px) {
        margin-top: 24px;
    }
`;

export const TouchBlock = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${convertSize(32)};

    @media (max-width: 1440px) {
        margin-top: 24px;
    }

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const FieldInput = styled(Input)`
    margin-right: ${convertSize(24)};

    @media (max-width: 1440px) {
        margin-right: 24px;
    }

    @media (max-width: 767px) {
        margin-right: 0;
    }

    @media (max-width: 479px) {
        width: 335px;
    }
`;

export const FieldBlock = styled.div`
    position: relative;
`;

export const SubmitButton = styled(Button)`
    width: ${convertSize(215)};
    height: ${convertSize(64)};

    @media (max-width: 1440px) {
        width: 215px;
        height: 64px;
    }

    @media (max-width: 767px) {
        width: 418px;
        margin-top: 48px;
    }

    @media (max-width: 479px) {
        width: 335px;
    }
`;

export const SubmitButtonText = styled(Typography)`
    color: #ffffff;
    letter-spacing: 0;
`;
