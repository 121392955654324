import { IconProps } from './icon.types';

const EmailLogo: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="white" />
            <path
                d="M21.3333 13.3333L16 16.6666L10.6667 13.3333V12L16 15.3333L21.3333 12V13.3333ZM21.3333 10.6666H10.6667C9.92667 10.6666 9.33333 11.26 9.33333 12V20C9.33333 20.3536 9.47381 20.6927 9.72386 20.9428C9.9739 21.1928 10.313 21.3333 10.6667 21.3333H21.3333C21.687 21.3333 22.0261 21.1928 22.2761 20.9428C22.5262 20.6927 22.6667 20.3536 22.6667 20V12C22.6667 11.6463 22.5262 11.3072 22.2761 11.0572C22.0261 10.8071 21.687 10.6666 21.3333 10.6666Z"
                fill="#212529"
            />
        </svg>
    );
};

export default EmailLogo;
