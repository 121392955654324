import { useContext } from 'react';

import * as Styled from './header.styles';
import { ICON_TYPES, Wrapper } from '../../ui-kit';
import { NavigateContext } from '../navigation-provider';
import { useLocation, useNavigate } from 'react-router-dom';
import PATH from '../../helpers/appRoutes';

const Header = () => {
    const { scrollToContactUsForm, scrollToIntroduction } = useContext(NavigateContext);

    const navigate = useNavigate();
    const location = useLocation();

    const onClick = () => {
        return location.pathname === PATH.home ? scrollToIntroduction() : navigate(PATH.home);
    };

    return (
        <Styled.Header>
            <Wrapper>
                <Styled.LogoIcon type={ICON_TYPES.logo} onClick={onClick} />
                {location.pathname !== PATH.thankYou && (
                    <Styled.ButtonBlock>
                        <Styled.ContactUsButton onClick={scrollToContactUsForm}>
                            <Styled.ButtonText type="regular-text">Contact us</Styled.ButtonText>
                            <Styled.ButtonIcon type={ICON_TYPES.yahooMail} />
                        </Styled.ContactUsButton>
                        <Styled.SiteLink href="https://codex-soft.com/" target="_blank">
                            <Styled.SiteTextBlock>
                                <Styled.SiteText>Powered by</Styled.SiteText>
                                <Styled.Site type="h6">CODEX</Styled.Site>
                            </Styled.SiteTextBlock>
                            <Styled.IconBlock>
                                <Styled.GlobalIcon type={ICON_TYPES.global} />
                            </Styled.IconBlock>
                        </Styled.SiteLink>
                    </Styled.ButtonBlock>
                )}
            </Wrapper>
        </Styled.Header>
    );
};

export default Header;
