import styled from 'styled-components';
import { Icon, Typography } from '../../../shared';
import convertSize from '../../../shared/helpers/convertSize';

export const BenefitContainer = styled.section`
    background-color: #f5f5f5;
    padding-top: ${convertSize(150)};

    @media (max-width: 1440px) {
        padding-top: 150px;
    }

    @media (max-width: 1240px) {
        padding: 150px 20px 0 20px;
        box-sizing: border-box;
    }
`;

export const BenefitWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: ${convertSize(1200)};
    width: auto;
    height: 100%;
    margin: 0 auto;

    @media (max-width: 1440px) {
        max-width: 1200px;
    }
`;

export const Title = styled(Typography)`
    text-align: center;
`;

export const BenefitBlock = styled.div`
    display: flex;
    margin-top: ${convertSize(32)};

    @media (max-width: 1440px) {
        margin-top: 32px;
    }

    @media (max-width: 1129px) {
        flex-wrap: wrap;
    }
`;

export const BenefitContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`;

export const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${convertSize(282)};
    height: ${convertSize(188)};
    margin-bottom: ${convertSize(24)};

    @media (max-width: 1440px) {
        width: 282px;
        height: 188px;
        margin-bottom: 24px;
    }
`;

export const StyledIcon = styled(Icon)`
    width: ${convertSize(120)};
    height: ${convertSize(120)};

    @media (max-width: 1440px) {
        width: 120px;
        height: 120px;
    }
`;
