import { useContext } from 'react';

import { ICON_TYPES, NavigateContext, Typography } from '../../../shared';
import * as Styled from './chat-bot-help.styles';

const cardsContent = [
    {
        icon: ICON_TYPES.onlineBooking,
        title: 'Can work 24/7',
        description:
            'The bot can interact with your customers anywhere, anytime using various platforms and messengers.',
    },
    {
        icon: ICON_TYPES.customerSupport,
        title: 'Fewer no-shows',
        description:
            'The chatbot can be designed to send reminders, confirmations, and scheduled notifications to encourage visitors to take action, reducing the no-show frequency.',
    },
    {
        icon: ICON_TYPES.service,
        title: 'Increase sales with a custom prediction mechanism',
        description:
            'The bot can make personalised suggestions for site visitors and advise goods and services based on the Consumer behaviour and preferences based on the end-of-season sales or holidays.',
    },
    {
        icon: ICON_TYPES.browser,
        title: 'Integrate with apps you use',
        description:
            'The bot can be set up to allow customers to communicate with your business via the bot itself, emails, services, push notifications, as well as send and receive data to and from calendars, appointment apps, and CRMs.',
    },
    {
        icon: ICON_TYPES.chatBot,
        title: 'Get a chatbot with a custom personality',
        description:
            'An AI-powered bot can adapt to the customer’s mood, intention, and tone  of the conversation on demand and provide more “human-like”, yet exact answers, keeping the conversation less machine-like.',
    },
];

const ChatBotHelp = () => {
    const { scrollToBespeakForm } = useContext(NavigateContext);
    return (
        <Styled.Container>
            <Styled.TitleBlock>
                <Typography type="h3">How do bots help businesses?</Typography>
            </Styled.TitleBlock>
            <Styled.Cards>
                {cardsContent.map(({ icon, description, title }, index) => (
                    <Styled.Card key={title}>
                        <Styled.StyledIcon type={icon} />
                        <Styled.CardTitle type="h6">{title}</Styled.CardTitle>
                        <Styled.CardDescription type="regular-text">{description}</Styled.CardDescription>
                    </Styled.Card>
                ))}
                <Styled.LastCard onClick={scrollToBespeakForm}>
                    <Styled.LastCardTitle>Get a custom bot today!</Styled.LastCardTitle>
                    <Styled.StyledIcon type={ICON_TYPES.arrowDown} />
                </Styled.LastCard>
            </Styled.Cards>
        </Styled.Container>
    );
};

export default ChatBotHelp;
