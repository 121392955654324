import { Typography } from '../../../shared';
import * as Styled from './customers-say.styles';

import axiell from '../../../assets/company-color-icons/axiell-logo.png';
import hp from '../../../assets/company-color-icons/hp-logo.png';
import loadown from '../../../assets/company-color-icons/loadown.png';
import macmillan from '../../../assets/company-color-icons/macmillan.png';

const cardsContent = [
    {
        icon: hp,
        width: 46,
        height: 46,
        description:
            'Codex have provided me with a great team for more than a decade. High quality engineers and managers that understand the dynamics of my business. I look forward to our relationship continuing.',
        reviewer: 'Nigel Watson',
        chief: 'New Business Incubation Director, HP Inc',
    },
    {
        icon: axiell,
        width: 80,
        height: 30,
        description:
            'They are knowledgeable, highly productive and efficient, and solve problems quickly. I trust them and the quality of their work. The lead developer has provided some great leadership to the team, which has made the partnership so successful.',
        reviewer: 'Samantha Diamond',
        chief: 'Vice President Digital Engagement, Axiell Group',
    },
    {
        icon: loadown,
        width: 120,
        height: 30,
        description:
            'Codex helped us create a complex, yet elegant solution our real-time marketing platform. The offshore team rapidly integrated into our development process and become productive within days.',
        reviewer: 'David Renard',
        chief: 'Co-founder, The Loadown',
    },
    {
        icon: macmillan,
        width: 46,
        height: 46,
        description:
            'Codex provides invaluable help when it is needed the most. Their contributions to the key components, broad technology expertise and persistent high quality of delivery make them the partner of choice for us.',
        reviewer: 'Rob Guttman',
        chief: 'Senior Vice President, Macmillan Publishers',
    },
];

const CustomersSay = () => {
    return (
        <Styled.Container>
            <Styled.TitleBlock>
                <Typography type="h3">What our customers say</Typography>
            </Styled.TitleBlock>
            <Styled.Cards>
                {cardsContent.map(({ icon, description, chief, reviewer, width, height }, index) => (
                    <Styled.Card key={index}>
                        <Styled.Review type="regular-text">{description}</Styled.Review>
                        <Styled.ReviewerContainer>
                            <Styled.CompanyIcon src={icon} width={width} height={height} />
                            <Styled.ReviewerBlock>
                                <Styled.Reviewer type="h6">{reviewer}</Styled.Reviewer>
                                <Styled.Chief>{chief}</Styled.Chief>
                            </Styled.ReviewerBlock>
                        </Styled.ReviewerContainer>
                    </Styled.Card>
                ))}
            </Styled.Cards>
        </Styled.Container>
    );
};

export default CustomersSay;
