import styled from 'styled-components';
import { Button, Input, Typography } from '../../../../../shared';
import convertSize from '../../../../../shared/helpers/convertSize';

export const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${convertSize(514)};
    height: ${convertSize(570)};
    background: #a4fcdc;
    border-radius: ${convertSize(12)};
    padding: ${convertSize(48)};
    box-sizing: border-box;

    @media (max-width: 1440px) {
        width: 514px;
        height: 570px;
        border-radius: 12px;
        padding: 48px;
    }

    @media (max-width: 1024px) {
        margin-top: 150px;
    }

    @media (max-width: 767px) {
        padding: 48px 20px;
        width: 473px;
    }

    @media (max-width: 479px) {
        border-radius: 0;
        max-width: 100%;
        width: 100%;
    }
`;

export const Title = styled(Typography)`
    font-size: ${convertSize(32)};
    margin-bottom: ${convertSize(12)};

    @media (max-width: 1440px) {
        font-size: 32px;
        margin-bottom: 12px;
    }
`;

export const Description = styled(Typography)`
    line-height: ${convertSize(24)};
    text-align: center;

    @media (max-width: 1440px) {
        line-height: 24px;
    }
`;

export const FieldBlock = styled.div`
    position: relative;
`;

export const FieldInput = styled(Input)`
    margin-top: ${convertSize(24)};

    @media (max-width: 1440px) {
        margin-top: 24px;
    }

    @media (max-width: 479px) {
        width: 335px;
    }
`;

export const SubmitButton = styled(Button)`
    width: ${convertSize(418)};
    height: ${convertSize(64)};
    margin-top: ${convertSize(48)};

    @media (max-width: 1440px) {
        width: 418px;
        height: 64px;
        margin-top: 48px;
    }

    @media (max-width: 479px) {
        width: 335px;
    }
`;

export const SubmitButtonText = styled(Typography)`
    color: #ffffff;
    letter-spacing: 0;
`;
