import { IconProps } from './icon.types';

const Apartment: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_619_1219)">
                <path
                    d="M108.281 49.4196H108.205C101.743 49.4196 96.4861 54.6766 96.4861 61.1383V80.0411C96.4861 86.5028 101.743 91.7599 108.205 91.7599H108.281C114.743 91.7599 120 86.5028 120 80.0411V61.1383C120 54.6766 114.743 49.4196 108.281 49.4196Z"
                    fill="#B2F0FB"
                />
                <path
                    d="M11.7187 91.7601H11.7952C18.2569 91.7601 23.5139 86.5031 23.5139 80.0414V61.1385C23.5139 54.6768 18.2569 49.4198 11.7952 49.4198H11.7187C5.25703 49.4198 0 54.6768 0 61.1385V80.0414C0 86.5031 5.25703 91.7601 11.7187 91.7601Z"
                    fill="#B2F0FB"
                />
                <path
                    d="M114.687 105.899H110.587L108.243 102.773L105.899 105.899H91.7602L89.5312 102.773L87.0727 105.899H70.5804L68.2031 102.773L65.8929 105.899H54.1073L51.7969 102.773L49.4196 105.899H32.9273L30.7031 102.773L28.2398 105.899H14.1007L11.757 102.773L9.4132 105.899H5.31258C2.38313 105.899 0 108.282 0 111.212V114.687C0 117.617 2.38313 120 5.31258 120H114.687C117.617 120 120 117.617 120 114.687V111.212C120 108.282 117.617 105.899 114.687 105.899Z"
                    fill="#B2F0FB"
                />
                <path
                    d="M51.8948 21.1798H43.3952C40.2288 21.1798 37.653 23.7556 37.653 26.922V31.8917C37.653 35.0581 40.2288 37.6339 43.3952 37.6339H51.8948C55.0612 37.6339 57.637 35.0581 57.637 31.8917V26.922C57.637 23.7556 55.0612 21.1798 51.8948 21.1798ZM52.9495 31.8917C52.9495 32.4732 52.4763 32.9464 51.8948 32.9464H43.3952C42.8137 32.9464 42.3405 32.4732 42.3405 31.8917V26.922C42.3405 26.3403 42.8137 25.8673 43.3952 25.8673H51.8948C52.4763 25.8673 52.9495 26.3403 52.9495 26.922V31.8917Z"
                    fill="#212529"
                />
                <path
                    d="M76.6048 21.1798H68.1052C64.9388 21.1798 62.363 23.7556 62.363 26.922V31.8917C62.363 35.0581 64.9388 37.6339 68.1052 37.6339H76.6048C79.7712 37.6339 82.347 35.0581 82.347 31.8917V26.922C82.347 23.7556 79.7709 21.1798 76.6048 21.1798ZM77.6595 31.8917C77.6595 32.4732 77.1863 32.9464 76.6048 32.9464H68.1052C67.5237 32.9464 67.0505 32.4732 67.0505 31.8917V26.922C67.0505 26.3403 67.5237 25.8673 68.1052 25.8673H76.6048C77.1863 25.8673 77.6595 26.3403 77.6595 26.922V31.8917Z"
                    fill="#212529"
                />
                <path
                    d="M51.8948 42.3595H43.3952C40.2288 42.3595 37.653 44.9353 37.653 48.1017V53.0714C37.653 56.2378 40.2288 58.8136 43.3952 58.8136H51.8948C55.0612 58.8136 57.637 56.2378 57.637 53.0714V48.1017C57.637 44.9355 55.0612 42.3595 51.8948 42.3595ZM52.9495 53.0716C52.9495 53.6531 52.4763 54.1263 51.8948 54.1263H43.3952C42.8137 54.1263 42.3405 53.6531 42.3405 53.0716V48.1019C42.3405 47.5202 42.8137 47.0472 43.3952 47.0472H51.8948C52.4763 47.0472 52.9495 47.5202 52.9495 48.1019V53.0716Z"
                    fill="#212529"
                />
                <path
                    d="M76.6048 42.3595H68.1052C64.9388 42.3595 62.363 44.9353 62.363 48.1017V53.0714C62.363 56.2378 64.9388 58.8136 68.1052 58.8136H76.6048C79.7712 58.8136 82.347 56.2378 82.347 53.0714V48.1017C82.347 44.9355 79.7709 42.3595 76.6048 42.3595ZM77.6595 53.0716C77.6595 53.6531 77.1863 54.1263 76.6048 54.1263H68.1052C67.5237 54.1263 67.0505 53.6531 67.0505 53.0716V48.1019C67.0505 47.5202 67.5237 47.0472 68.1052 47.0472H76.6048C77.1863 47.0472 77.6595 47.5202 77.6595 48.1019V53.0716Z"
                    fill="#212529"
                />
                <path
                    d="M51.8948 63.5396H43.3952C40.2288 63.5396 37.653 66.1153 37.653 69.2817V74.2514C37.653 77.4178 40.2288 79.9936 43.3952 79.9936H51.8948C55.0612 79.9936 57.637 77.4178 57.637 74.2514V69.2817C57.637 66.1153 55.0612 63.5396 51.8948 63.5396ZM52.9495 74.2514C52.9495 74.8329 52.4763 75.3061 51.8948 75.3061H43.3952C42.8137 75.3061 42.3405 74.8329 42.3405 74.2514V69.2817C42.3405 68.7 42.8137 68.2271 43.3952 68.2271H51.8948C52.4763 68.2271 52.9495 68.7 52.9495 69.2817V74.2514Z"
                    fill="#212529"
                />
                <path
                    d="M76.6048 63.5396H68.1052C64.9388 63.5396 62.363 66.1153 62.363 69.2817V74.2514C62.363 77.4178 64.9388 79.9936 68.1052 79.9936H76.6048C79.7712 79.9936 82.347 77.4178 82.347 74.2514V69.2817C82.347 66.1153 79.7709 63.5396 76.6048 63.5396ZM77.6595 74.2514C77.6595 74.8329 77.1863 75.3061 76.6048 75.3061H68.1052C67.5237 75.3061 67.0505 74.8329 67.0505 74.2514V69.2817C67.0505 68.7 67.5237 68.2271 68.1052 68.2271H76.6048C77.1863 68.2271 77.6595 68.7 77.6595 69.2817V74.2514Z"
                    fill="#212529"
                />
                <path
                    d="M11.757 70.5996C10.4625 70.5996 9.41321 71.6489 9.41321 72.9434V105.899H14.1007V72.9434C14.1007 71.6489 13.0514 70.5996 11.757 70.5996Z"
                    fill="#212529"
                />
                <path
                    d="M108.243 70.5996C106.949 70.5996 105.899 71.6489 105.899 72.9434V105.899H110.587V72.9434C110.587 71.6489 109.537 70.5996 108.243 70.5996Z"
                    fill="#212529"
                />
                <path
                    d="M82.3852 9.4132H82.347V7.03125C82.347 3.15422 79.1927 0 75.3157 0H44.6843C40.8073 0 37.6531 3.15422 37.6531 7.03125V9.4132H37.6149C32.4455 9.4132 28.2399 13.6188 28.2399 18.7882V105.899H32.9274V101.174H49.4196V105.899H54.1071V91.7508C54.1071 90.4584 55.1585 89.407 56.4509 89.407H63.5489C64.8413 89.407 65.8927 90.4584 65.8927 91.7508V105.899H70.5802V101.174H87.0724V105.899H91.7599V18.7882C91.7602 13.6188 87.5543 9.4132 82.3852 9.4132ZM42.3406 7.03125C42.3406 5.73891 43.392 4.6875 44.6843 4.6875H75.3155C76.6078 4.6875 77.6592 5.73891 77.6592 7.03125V9.4132H42.3406V7.03125ZM70.5804 96.4861V91.7508C70.5804 87.8737 67.4262 84.7195 63.5492 84.7195H56.4511C52.5741 84.7195 49.4199 87.8737 49.4199 91.7508V96.4861H32.9274V18.7882C32.9274 16.2035 35.0302 14.1007 37.6149 14.1007H82.3852C84.9699 14.1007 87.0727 16.2035 87.0727 18.7882V96.4861H70.5804Z"
                    fill="#212529"
                />
            </g>
            <defs>
                <clipPath id="clip0_619_1219">
                    <rect width="120" height="120" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Apartment;
