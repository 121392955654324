import React from 'react';
import { RegularText, H3Text, H1Text, H6Text } from './typography.styles';

type TypographyType = 'regular-text' | 'h6' | 'h3' | 'h1';

const getTypographyElementByType = (type: TypographyType): React.FC => {
    switch (type) {
        case 'h1':
            return H1Text;
        case 'h3':
            return H3Text;
        case 'h6':
            return H6Text;
        default:
            return RegularText;
    }
};

export interface TypographyProps extends React.HTMLAttributes<HTMLParagraphElement> {
    type?: TypographyType;
    children: React.ReactNode;
}

const Typography: React.FC<TypographyProps> = ({ type = 'regular-text', children, ...props }) => {
    const TypographyElement: React.FC<TypographyProps> = getTypographyElementByType(type);

    return <TypographyElement {...props}>{children}</TypographyElement>;
};

export default Typography;
