import * as Styled from './how-work.styles';
import { ICON_TYPES, Typography } from '../../../shared';

const content = [
    { icon: ICON_TYPES.email, description: 'Receive a request' },
    {
        icon: ICON_TYPES.discussion,
        description: 'Identify use cases, goals and audience',
    },
    {
        icon: ICON_TYPES.hierarchicalStructure,
        description: 'Define the flow to cover the possible scenarios',
    },
    {
        icon: ICON_TYPES.brain,
        description: 'Add Machine Learning and natural language processing components',
    },
    {
        icon: ICON_TYPES.responsive,
        description: 'Provide UX/UI design and development',
    },
    {
        icon: ICON_TYPES.liveStreaming,
        description: 'The bot is live; provide post-release support and maintenance',
    },
];

const HowWork = () => {
    return (
        <Styled.HowWorkContainer>
            <Styled.HowWorkContent>
                <Typography type="h3">How we work</Typography>
                <Styled.Cards>
                    {content.map(({ icon, description }, index, arr) => {
                        return (
                            <Styled.Card key={icon}>
                                <Styled.IconBLock>
                                    <Styled.StyledIcon type={icon} />
                                    {index !== arr.length - 1 && <Styled.DesktopArrow type={ICON_TYPES.arrow} />}
                                </Styled.IconBLock>
                                <Styled.Description type="h6">{description}</Styled.Description>
                                {index !== arr.length - 1 && <Styled.MobileArrow type={ICON_TYPES.arrow} />}
                            </Styled.Card>
                        );
                    })}
                </Styled.Cards>
            </Styled.HowWorkContent>
        </Styled.HowWorkContainer>
    );
};

export default HowWork;
