import { IconProps } from './icon.types';

const HeartRate: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_619_1237)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M94.4838 42.8766C113.049 10.7205 78.9087 -10.6489 59.9967 8.26338C41.0844 -10.6489 6.94417 10.7205 25.5096 42.8766C34.0539 57.6758 51.2868 71.2443 59.9967 73.5781C68.7065 71.2441 85.9392 57.6758 94.4838 42.8766Z"
                    fill="#B2F0FB"
                />
                <path
                    d="M119.704 84.5355C118.759 81.2829 115.309 78.3233 109.971 79.51C109.933 79.5135 87.7529 84.8779 87.7116 84.8843C87.1003 81.357 83.6133 78.6069 80.0331 79.0096C77.5842 79.2325 62.165 80.9897 59.6071 81.2264C57.0056 81.4664 53.6016 80.9547 49.6597 80.362C43.1573 79.3843 35.7874 78.2762 28.3937 79.9427C19.6224 81.9197 12.9175 87.3549 7.89646 96.5591L0.913347 108.891C-0.302891 111.079 -0.304766 113.72 0.90866 115.782C2.02927 117.688 4.00938 118.825 6.20536 118.825C6.20582 118.826 30.6909 118.825 30.6914 118.825C36.7257 119.061 37.4785 115.896 40.29 111.629L54.2699 112.296C64.5979 112.788 67.8948 112.264 77.7846 108.556C85.4376 105.686 95.0004 101.772 102.684 98.6273C108.186 96.3753 112.938 94.4304 114.722 93.8065C119.276 92.1782 120.686 87.9004 119.704 84.5355ZM113.163 89.3857C103.833 92.9137 86.6231 100.314 76.1387 104.167C66.8286 107.657 64.1983 108.076 54.4932 107.613L39.1465 106.881C38.2952 106.844 37.5011 107.259 37.051 107.974L34.2853 112.37C33.6908 113.315 33.3533 113.739 33.0824 113.882C32.656 114.109 31.5795 114.137 30.6904 114.137H6.20536C4.63261 114.055 4.36963 112.32 5.00154 111.185C5.02006 111.152 11.9865 98.8502 12.0027 98.8193C21.4844 81.8166 34.6439 82.8005 48.9621 84.9975C58.0606 86.5657 60.0913 85.9048 70.2898 84.7816C74.6794 84.2952 79.2186 83.7922 80.4641 83.6772C81.8184 83.5328 83.0688 84.7167 83.1499 86.0724C83.1905 86.5402 83.2983 87.7836 80.1781 89.1344C73.7062 91.9365 66.8314 94.2862 61.316 95.5815C60.0557 95.8775 59.274 97.139 59.5701 98.3991C59.8661 99.6594 61.1278 100.44 62.3876 100.145C68.1616 98.7891 75.3247 96.3439 82.0408 93.4361C84.3824 92.4222 85.8318 91.2001 86.6967 89.9512L111.007 84.0815C113.177 83.6052 114.743 84.2631 115.202 85.8434C115.569 87.1048 115.08 88.6877 113.163 89.3857Z"
                    fill="#212529"
                />
                <path
                    d="M14.0963 39.7142H45.5779C46.518 39.7142 47.3672 39.1526 47.7349 38.2875L52.7872 26.4052L65.0489 55.2424C65.4169 56.1075 66.2659 56.6691 67.206 56.6691C68.1461 56.6691 68.9951 56.1075 69.3631 55.2424L75.9655 39.7142H105.897C108.994 39.6038 109.01 35.1439 105.897 35.0264H74.4153C73.4752 35.0264 72.6262 35.588 72.2582 36.4532L67.206 48.3354L54.9442 19.498C54.5763 18.6329 53.7273 18.0713 52.7872 18.0713C51.847 18.0713 50.9978 18.6329 50.6301 19.498L44.0276 35.0262H14.0963C10.9928 35.1401 10.9893 39.5989 14.0963 39.7142Z"
                    fill="#212529"
                />
            </g>
            <defs>
                <clipPath id="clip0_619_1237">
                    <rect width="120" height="120" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default HeartRate;
