import { IconProps } from './icon.types';

const MoneyBag: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_982_1267)">
        <path d="M60 103.527C45.7312 103.527 34.1231 91.9183 34.1231 77.6498C34.1231 63.3813 45.7312 51.7729 60 51.7729C74.2685 51.7729 85.8769 63.3813 85.8769 77.6498C85.8769 91.9183 74.2685 103.527 60 103.527Z" fill="#B3F5FE"/>
        <path d="M63.0185 75.2099C61.0111 74.5002 58.792 73.644 57.5543 72.6732C56.7284 72.0256 57.0729 69.635 58.7552 69.1283C60.9258 68.4739 62.6145 69.6667 62.8116 69.8141C63.7674 70.603 65.1837 70.513 66.03 69.5893C66.9084 68.6312 66.8461 67.1267 65.8514 66.2467C65.3189 65.7732 64.0507 64.9674 62.3435 64.5383V63.5298C62.3435 62.2353 61.294 61.186 59.9998 61.186C58.7053 61.186 57.656 62.2353 57.656 63.5298V64.5695C52.1175 66.0702 50.7741 73.3126 54.6612 76.3614C56.0888 77.481 58.0573 78.4278 61.4562 79.629C63.6284 80.3968 63.582 82.1518 63.4613 82.8706C63.214 84.3404 61.9779 85.9285 59.847 85.9426C57.6874 85.9567 57.0694 85.8589 55.4299 84.7864C54.3467 84.0779 52.8942 84.3814 52.1855 85.4649C51.477 86.5482 51.7807 88.0006 52.864 88.7094C54.6818 89.8986 56.0182 90.3753 57.656 90.546V91.7699C57.656 93.0643 58.7053 94.1136 59.9998 94.1136C61.294 94.1136 62.3435 93.0643 62.3435 91.7699V90.256C65.5587 89.2618 67.6149 86.436 68.0836 83.6481C68.7148 79.8948 66.6792 76.5036 63.0185 75.2099Z" fill="#171A1D"/>
        <path d="M79.1918 37.466C82.339 36.768 84.7003 33.9567 84.7003 30.6028V30.5646C84.7003 26.6876 81.5461 23.5334 77.6691 23.5334H74.0517L78.3066 12.8962C80.772 6.73172 76.2335 0 69.5754 0H50.4244C43.7855 0 39.2208 6.71461 41.6932 12.896L45.9481 23.5331H42.3307C38.4537 23.5331 35.2995 26.6873 35.2995 30.5644V30.6026C35.2995 33.9567 37.6608 36.768 40.808 37.4658C26.8772 47.801 17.4143 67.9355 17.4143 84.4744C17.4143 95.7007 21.8109 104.966 30.1291 111.268C37.6695 116.981 47.9986 120 60 120C72.0014 120 82.3303 116.981 89.8706 111.268C98.1886 104.966 102.585 95.7007 102.585 84.4746C102.585 67.9547 93.1423 47.816 79.1918 37.466ZM46.0456 11.1553C44.8118 8.07094 47.0656 4.6875 50.4244 4.6875H69.5754C72.8963 4.6875 75.2023 8.03555 73.9542 11.1553L69.0031 23.5334H50.997L46.0456 11.1553ZM39.9872 30.6026V30.5644C39.9872 29.272 41.0384 28.2206 42.3309 28.2206H77.6688C78.9612 28.2206 80.0126 29.272 80.0126 30.5644V30.6026C80.0126 31.8949 78.9612 32.9463 77.6688 32.9463C71.677 32.9463 54.5477 32.9463 49.1245 32.9463H42.3309C41.0384 32.9463 39.9872 31.8949 39.9872 30.6026ZM60 115.312C41.6789 115.312 22.102 107.211 22.102 84.4744C22.102 67.7941 33.1413 45.1322 49.6226 37.6338H70.377C86.8584 45.1322 97.898 67.7944 97.898 84.4744C97.898 107.211 78.3211 115.312 60 115.312Z" fill="#171A1D"/>
        </g>
          <defs>
        <clipPath id="clip0_982_1267">
        <rect width="120" height="120" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    );
};

export default MoneyBag;
