import { IconProps } from './icon.types';

const Profits: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_982_1277)">
                <path d="M56.4605 114.098H37.6531V69.395H56.4605V114.098Z" fill="#A4FCDC" />
                <path d="M108.233 114.098H89.426V36.4482H108.233V114.098Z" fill="#A4FCDC" />
                <path
                    d="M37.6435 55.3119C23.375 55.3119 11.7666 43.7035 11.7666 29.435C11.7666 15.1665 23.375 3.55811 37.6435 3.55811C51.912 3.55811 63.5206 15.1665 63.5206 29.435C63.5206 43.7035 51.9122 55.3119 37.6435 55.3119Z"
                    fill="#A4FCDC"
                />
                <path
                    d="M30.5075 40.4943C32.3253 41.6836 33.6617 42.1603 35.2997 42.3311V43.555C35.2997 44.8495 36.349 45.8988 37.6435 45.8988C38.9379 45.8988 39.9872 44.8495 39.9872 43.555V42.0412C43.2021 41.0468 45.2585 38.2211 45.7273 35.433C46.3582 31.68 44.3227 28.2888 40.6622 26.9948C38.6546 26.2851 36.4355 25.4287 35.198 24.4584C34.3721 23.8101 34.7168 21.4202 36.3989 20.9135C38.566 20.2607 40.2518 21.4474 40.4541 21.5986C41.4106 22.3893 42.8264 22.2944 43.6728 21.3677C44.5456 20.4117 44.4741 18.9255 43.5183 18.0529C42.9014 17.482 41.5388 16.7137 39.9872 16.3237V15.3154C39.9872 14.021 38.9379 12.9717 37.6435 12.9717C36.349 12.9717 35.2997 14.021 35.2997 15.3154V16.3549C29.76 17.8558 28.4185 25.0987 32.3051 28.147C33.7325 29.2664 35.701 30.213 39.0999 31.4146C41.2721 32.1825 41.2254 33.9375 41.1047 34.6563C40.8577 36.1258 39.6214 37.7139 37.4909 37.7282C35.3323 37.7442 34.7133 37.6446 33.0739 36.5721C31.9908 35.8636 30.5382 36.1671 29.8294 37.2501C29.1207 38.3332 29.4244 39.7856 30.5075 40.4943Z"
                    fill="#212529"
                />
                <path
                    d="M117.656 111.755H82.3469V55.2656C82.3469 53.9712 81.2977 52.9219 80.0032 52.9219H65.8833C64.5888 52.9219 63.5395 53.9712 63.5395 55.2656V111.755H30.574V88.212C30.574 86.9175 29.5247 85.8682 28.2302 85.8682H14.1103C12.8159 85.8682 11.7666 86.9175 11.7666 88.212V111.755H2.34375C1.0493 111.755 0 112.804 0 114.098C0 115.393 1.0493 116.442 2.34375 116.442H117.656C118.951 116.442 120 115.393 120 114.098C120 112.804 118.951 111.755 117.656 111.755ZM16.4541 111.755V90.5557H25.8865V111.755H16.4541ZM68.227 111.755V57.6094H77.6594V111.755H68.227V111.755Z"
                    fill="#212529"
                />
            </g>
            <defs>
                <clipPath id="clip0_982_1277">
                    <rect width="120" height="120" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Profits;
