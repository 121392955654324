import { FieldMetaState } from 'react-final-form';

import * as Styled from './field-error.styles';

interface FieldErrorProps {
    meta: FieldMetaState<string>;
}

const FieldError: React.FC<FieldErrorProps> = ({ meta }) => {
    return <>{meta.touched && meta.error && <Styled.Error>{meta.error}</Styled.Error>}</>;
};

export default FieldError;
